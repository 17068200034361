import React from "react";
import "./styles.css";

const UserMessage = ({ children, onChangeText, search_text }) => (
  <div className="list_user_message">
    <input
      className="form-control"
      value={search_text}
      onChange={onChangeText}
      placeholder="Search..."
    />
    {children}
  </div>
);

export default UserMessage;
