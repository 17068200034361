import React from "react";
import "./styles.css";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const App = ({
  placeholder,
  message,
  type,
  value,
  onChange,
  label,
  label_title,
  data,
  data_key,
  isCreatable,
  isStatic,
  isMulti,
  required,
}) => (
  <div className="form-group">
    {label && (
      <label>
        {label_title}
        {required ? <span className="required">*</span> : ""}:
      </label>
    )}
    {isStatic ? (
      <Select
        onChange={onChange}
        options={data}
        isMulti={isMulti}
        value={value}
      />
    ) : isCreatable ? (
      <CreatableSelect isMulti onChange={onChange} options={value} />
    ) : (
      <select
        type={type}
        value={value}
        onChange={onChange}
        className="form-control"
        placeholder={placeholder}
        options={data}
      >
        <option>Choose</option>
        {data.map((item, i) => {
          return <option key={i}>{data_key ? item[data_key] : item}</option>;
        })}
      </select>
    )}
    {message && <small className="form-text text-muted">{message}</small>}
  </div>
);

export default App;
