import React from "react";
import { Header } from "../components/Header";
import { TableOrder } from "../components/Table";
import api from "../utils/api";
import { URL } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";
import { Filter } from "../components/Filter";
import { Loader } from "../components/Loader";
import { connect } from "react-redux";

let copyData = [];

class OrderScreen extends React.Component {
  state = {
    columns: ["Order ID#", "Cost", "Address", "Consumer", "Date"],
    isLoading: true,
    data: [],
    search_text: "",
  };

  componentDidMount = async () => {
    await this.getProfile();
    this.getData(true, this.state.user);
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({ user });
    }
  };

  getData(isLoading) {
    this.setState({ isLoading, data: [] });

    let data = {
      model: "order",
      organization: [this.props.organization._id],
      token: this.state.user.token,
      seller: [this.state.user.user_id],
    };
    let config = {
      method: "post",
      url: URL + "/get_order_info",
      data,
    };

    api(config)
      .then((data) => {
        this.setState({
          isLoading: false,
          data: data.data,
        });
        copyData = data.data.slice(0);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleSearch(e) {
    let new_array = [],
      text = e.target.value.toUpperCase();
    for (let i = 0; i < copyData.length; i++) {
      if (
        copyData[i]._id.toUpperCase().indexOf(text) !== -1 ||
        copyData[i].address.toUpperCase().indexOf(text) !== -1 ||
        copyData[i].cost.toString().toUpperCase().indexOf(text) !== -1 ||
        (copyData[i].consumer[0].email &&
          copyData[i].consumer[0].email.toUpperCase().indexOf(text) !== -1) ||
        (copyData[i].consumer[0].phone_number &&
          copyData[i].consumer[0].phone_number.toUpperCase().indexOf(text) !==
            -1)
      ) {
        new_array.push(copyData[i]);
      }
    }

    this.setState({
      data: new_array,
      search_text: e.target.value,
    });
  }

  render() {
    return (
      <>
        <Header title="Orders" showAddBtn={false} />
        <Filter
          search_text={this.state.search_text}
          onChange={this.handleSearch.bind(this)}
        />
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <TableOrder data={this.state.data} columns={this.state.columns} />
        )}
      </>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization } = state.Organization;
  return {
    organization,
  };
};

export default connect(mapPropsToState)(OrderScreen);
