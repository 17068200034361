import React from "react"
import { Link } from "react-router-dom"
import "./styles.css"
import { Product } from "../Product"

const TableOrg = ({className, data, handlePress, columns, showCard}) => <table className={`table table-hover ${className}`}>
    <thead>
        <tr>
            {
                columns.map((item, i) => {
                    return  <th key={i}>{item}</th>
                })
            }
        </tr>
    </thead>
    <tbody>
        {
            data.length === 0 ? <tr>
                <td align="center" colSpan={10}>No data found</td>
            </tr>
            :
            data.map((item, i) => {
                return <tr key={i} onClick={() => handlePress(item)}>
                    {
                        showCard ? <td>
                        <Product title="Fanta 1L Soda"/>
                        </td>
                        :
                        <td><b>Huzamart</b></td>
                    }
                    <td><Link to=""><b>View</b></Link></td>
                    <td><Link to=""><b>View</b></Link></td>
                    <td><Link to=""><b>View</b></Link></td>
                </tr>
            })
        }
    </tbody>
</table>

export default TableOrg