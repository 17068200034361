import React from "react";
import { Header } from "../components/Header";
import { TableOrgTransaction } from "../components/Table";
import { getStorage } from "../utils/sessionStorage";
import { Loader } from "../components/Loader";
import { connect } from "react-redux";
import { Modal } from "../components/Modal";
import NewOrganizationScreen from "./NewOrganizationScreen";
import { PayService } from "../components/PayService";
import { URL } from "../utils/CONSTANTS";
import api from "../utils/api";

class OrganizationScreen extends React.Component {
  state = {
    data: [],
    rows: ["name"],
    isLoading: true,
    user: {},
    show_modal: false,
    tabValue: 0,
    services: [],
    isLoadingTransaction: false,
    transactions: [],
    show_payment_modal: false,
  };

  componentDidMount = async () => {
    await this.getProfile();
    this.getTransactions();
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.user_id) {
      this.setState({ user });
    } else {
      this.gotoLogin();
    }
  };

  getTransactions() {
    const { organization } = this.props;
    const { user } = this.state;

    if (!organization._id) return;
    if (!user.token) return;

    this.setState({
      isLoading: true,
    });

    let data = {
      organization: organization._id,
      model: "orgtransaction",
      token: user.token,
    };
    let config = {
      method: "post",
      url: URL + "/get_transaction_info",
      data,
    };

    api(config)
      .then((data) => {
        this.setState({
          transactions: data.data,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({
          isLoading: false,
        });
      });
  }

  gotoLogin() {
    window.location.href = "/";
  }

  openModal(selected_org) {
    this.setState({
      show_modal: true,
      selected_org,
    });
  }

  close_modal() {
    this.setState({
      show_modal: false,
      show_payment_modal: false,
    });
  }

  handleChange = (event, tabValue) => {
    this.setState({
      tabValue,
    });
  };

  handlePressPayModal() {
    this.setState({
      show_payment_modal: true,
    });
  }

  render() {
    return (
      <>
        <Header
          title={"Payment history"}
          showAddBtn={true}
          route="/app/create_organization"
          handlePress={this.handlePressPayModal.bind(this)}
          button_text="Pay Now"
        />
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <TableOrgTransaction data={this.state.transactions} />
          </>
        )}

        <Modal
          show={this.state.show_modal}
          handleClose={this.close_modal.bind(this)}
          size="lg"
          centered
        >
          <NewOrganizationScreen data={this.state.selected_org} />
        </Modal>
        <Modal
          show={this.state.show_payment_modal}
          handleClose={this.close_modal.bind(this)}
          // size="lg"
          centered
          title="Make Payment"
        >
          <PayService
            user={this.state.user}
            organization={this.props.organization}
          />
        </Modal>
      </>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization } = state.Organization;
  return {
    organization,
  };
};

export default connect(mapPropsToState)(OrganizationScreen);
