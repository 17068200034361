import React from "react";
import { Button } from "../Form/Button";
import { Input } from "../Form/Input";
import { Select } from "../Form/Select";
import "./styles.css";
import { Alert } from "../Alert";

const NewOrg = ({
  onChangeInput,
  error,
  onSubmit,
  categories,
  category,
  onChangeCategory,
  name,
  address,
  onChangeImages,
  isSubmitting,
  isAirtelmomo,
  onChangeCheckbox,
  isMTNmomo,
  mobilepaycode_airtel,
  mobilepaycode_mtn,
  isActivated,
  isAdmin,
}) => (
  <div className="row">
    <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          {error.success_message && (
            <Alert className="alert-success">{error.success_message}</Alert>
          )}
          {error.error_message && (
            <Alert className="alert-danger"> {error.error_message}</Alert>
          )}
          <form>
            <Input
              label={true}
              label_title="Name:"
              value={name}
              onChange={(e) => onChangeInput(e, "name")}
            />
            <Input
              label={true}
              label_title="Address:"
              value={address}
              onChange={(e) => onChangeInput(e, "address")}
            />
            <Select
              data={categories}
              label={true}
              label_title="Category:"
              isStatic
              isMulti
              value={category}
              onChange={onChangeCategory}
            />
            <div className="row">
              <div className="col-md-12">
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => onChangeCheckbox(e, "isMTNmomo")}
                    checked={isMTNmomo}
                  />{" "}
                  MTN pay code/Momo number
                </label>
                {isMTNmomo && (
                  <>
                    <div className="form-group">
                      <label>MTN Code</label>
                      <input
                        className="form-control"
                        value={mobilepaycode_mtn}
                        onChange={(e) => onChangeInput(e, "mobilepaycode_mtn")}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-12">
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => onChangeCheckbox(e, "isAirtelmomo")}
                    checked={isAirtelmomo}
                  />{" "}
                  Airtel pay code/Airtel money number
                </label>
                {isAirtelmomo && (
                  <>
                    <div className="form-group">
                      <label>Airtel Code</label>
                      <input
                        className="form-control"
                        value={mobilepaycode_airtel}
                        onChange={(e) =>
                          onChangeInput(e, "mobilepaycode_airtel")
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          <form>
            <Input
              type="file"
              label={true}
              label_title="Upload Images"
              onChange={onChangeImages}
            />
            {isAdmin && 
              (<div className="row">
                <div className="col-md-12">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) => onChangeCheckbox(e, "isActivated")}
                      checked={isActivated}
                    />{" "}
                    Activate/Deactivate E-Shop
                  </label>
                </div>
              </div>)
            }
          </form>
        </div>
      </div>
    </div>
    <div className="col-md-12 mt-1">
      <div className="card">
        <div className="card-body">
          <Button
            title="Submit"
            className="btn-primary"
            isSubmitting={isSubmitting}
            handlePress={onSubmit}
          />
        </div>
      </div>
    </div>
  </div>
);

export default NewOrg;
