import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { Product } from "../Product";

const TableOrg = ({ className, data, handlePress, columns, showCard }) => (
  <table className={`table table-hover ${className}`}>
    <thead>
      <tr>
        {columns.map((item, i) => {
          return <th key={i}>{item}</th>;
        })}
      </tr>
    </thead>
    <tbody>
      {data.length === 0 ? (
        <tr>
          <td align="center" colSpan={10}>
            No data found
          </td>
        </tr>
      ) : (
        data.map((item, i) => {
          return (
            <tr key={i}>
              <td>
                <Product file_url={[item.image]} title={item.title} />
              </td>
              <td>
                <Link to="#">
                  <b>{item.background}</b>
                </Link>
              </td>
            </tr>
          );
        })
      )}
    </tbody>
  </table>
);

export default TableOrg;
