import React from "react";
import { connect } from "react-redux";
import { Header } from "../components/Header";
import { Loader } from "../components/Loader";
import { NewShop } from "../components/NewShop";
import api from "../utils/api";
import { URL, countries } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";

class NewProductScreen extends React.Component {
  state = {
    category: "",
    shop_name: "",
    images: [],
    isSubmitting: false,
    user: {},
    error: {},
    categories: [],
    isLoading: false,
    remote_file: [],
    shop_id: "",
    organization: [],
    status: "",
    shop_type: "",
    sections: [],
    country: "",
  };

  componentDidMount = async () => {
    await this.getProfile();

    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.shop_id
    ) {
      this.setState({
        isLoading: true,
        product_id: this.props.match.params.shop_id,
      });

      this.getData(true, this.props.match.params.shop_id);
    }
  };

  getData(isLoading, _id) {
    this.setState({ isLoading });

    let data = {
      model: "shop",
      id: [_id],
      token: this.state.user.token,
    };
    let config = {
      method: "post",
      url: URL + "/get_shop_info",
      data,
    };

    api(config)
      .then((data) => {
        const { shop_name, section, shop_type, _id, shop_url, country } =
          data.data[0];

        let new_section = [];
        let parsed_data = JSON.parse(section);
        for (let i = 0; i < parsed_data.length; i++) {
          new_section.push({
            label: parsed_data[i],
            value: parsed_data[i],
          });
        }

        this.setState({
          isLoading: false,
          remote_file: shop_url,
          shop_url,
          shop_id: _id,
          sections: new_section,
          shop_type,
          shop_name,
          country,
          organization: [this.props.organization._id],
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({ user });
    }
  };

  onChangeImages(e) {
    let images = [];
    for (var i = 0; i < e.target.files.length; i++) {
      images[i] = e.target.files.item(i);
    }
    images = images.filter((image) =>
      image.name.match(/\.(jpg|jpeg|png|gif)$/)
    );
    let message = `${images.length} valid image(s) selected`;
    this.setState({ images, message });
  }

  onChangeInput(e, key) {
    this.setState({
      [key]: e.target.value,
      error: {},
    });
  }

  onChangeSection(e) {
    this.setState({
      sections: e,
      error: {},
    });
  }

  onChangeCountry(e) {
    this.setState({
      country: e.target.value,
      error: {},
    });
  }

  onSubmit() {
    const { shop_name, shop_id, shop_type, sections, user, images, country } =
      this.state;

    if (shop_name === "") {
      alert("Shop name is required");
    } else if (shop_type === "") {
      alert("Shop type is required");
    } else if (sections.length === 0) {
      alert("Section is required");
    } else if (!this.props.organization._id) {
      alert("Organization is missing");
    } else if (country === "") {
      alert("Country is required");
    } else {
      this.setState({
        isSubmitting: true,
      });
      let new_sections = [];
      for (let i = 0; i < sections.length; i++) {
        new_sections.push(sections[i].value);
      }

      let formData = new FormData();

      formData.append("model", "shop");
      formData.append("shop_name", shop_name);
      formData.append("user", '["' + user.user_id + '"]');
      formData.append("token", user.token);
      formData.append("country", country);
      formData.append("shop_type", shop_type);
      formData.append("section", JSON.stringify(new_sections));
      formData.append("date", new Date().getTime());
      if (shop_id !== "") {
        formData.append(
          "organization",
          '["' + this.state.organization._id + '"]'
        );
      } else {
        formData.append(
          "organization",
          '["' + this.props.organization._id + '"]'
        );
      }

      if (images.length > 0) {
        images.forEach((element, i) => {
          formData.append(("file" + i).replace(/\s/g, ""), element);
        });
      }

      let url = URL + "/add_shop_info";

      if (this.state.shop_id && this.state.shop_id !== "") {
        formData.append("id", this.state.shop_id);
        url = URL + "/update_shop_info";
      }

      let config = {
        method: "post",
        url,
        data: formData,
        headers: {
          "content-type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };

      api(config)
        .then((data) => {
          console.log(data.data);
          this.state.error.success_message =
            shop_id !== ""
              ? "Data updated successfully"
              : "Data added successfully";

          this.setState({
            isSubmitting: false,
            error: this.state.error,
            shop_name: "",
            shop_id: "",
            shop_type: "",
            sections: [],
            images: [],
          });

          if (this.state.shop_id !== "") {
            this.getData(true, this.state.shop_id);
          }
        })
        .catch((err) => {
          if (err.response) {
            this.state.error.error_message = err.response.data.message;
          }
          this.setState({
            isSubmitting: false,
            error: this.state.error,
          });
        });
    }
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <Header
              title={this.state.product_id ? "Edit Shop" : "Create Shop"}
              showAddBtn={false}
            />
            <NewShop
              error={this.state.error}
              onChangeInput={this.onChangeInput.bind(this)}
              onChangeImages={this.onChangeImages.bind(this)}
              onChangeSection={this.onChangeSection.bind(this)}
              sections={this.state.sections}
              shop_name={this.state.shop_name}
              isSubmitting={this.state.isSubmitting}
              onSubmit={this.onSubmit.bind(this)}
              country={this.state.country}
              onChangeCountry={this.onChangeCountry.bind(this)}
            />
          </>
        )}
      </>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization } = state.Organization;
  return {
    organization,
  };
};

export default connect(mapPropsToState)(NewProductScreen);
