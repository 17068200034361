import React from "react";
import { Header } from "../components/Header";
import { Loader } from "../components/Loader";
import { ProductDetail } from "../components/ProductDetail";
import api from "../utils/api";
import { URL } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";

class ProductDetailScreen extends React.Component {
  state = {
    data: [],
    selected_img: 0,
  };

  componentDidMount = async () => {
    await this.getProfile();
    if (this.props.match.params) {
      const { product_id } = this.props.match.params;
      this.getData(true, product_id);
    } else {
      alert("Missing product ID");
    }
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({ user });
    }
  };

  getData(isLoading, _id) {
    this.setState({ isLoading, data: [] });
    console.log("debug******: ", _id)
    let data = {
      model: "product",
      id: _id,
      token: this.state.user.token,
    };
    let config = {
      method: "post",
      url: URL + "/get_product_info",
      data,
    };

    api(config)
      .then((data) => {
        this.setState({
          isLoading: false,
          data: data.data,
        });
        // console.log(data.data);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  onSelectImage(index) {
    this.setState({
      selected_img: index,
    });
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          this.state.data.length !== 0 && (
            <>
              <Header
                title={this.state.data[0].title}
                showAddBtn={false}
                showEdit={true}
                route={`/app/create_product/${this.state.data[0]._id}`}
              />
              <ProductDetail
                data={this.state.data}
                onSelectImage={this.onSelectImage.bind(this)}
                selected_img={this.state.selected_img}
              />
            </>
          )
        )}
      </>
    );
  }
}

export default ProductDetailScreen;
