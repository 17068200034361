import React from "react";
import "./styles.css";
import { User } from "../User";
import { format } from "timeago.js";

const TableNotification = ({
  className,
  data,
  handlePress,
  columns,
  showCard,
}) => (
  <table className={`table table-hover ${className}`}>
    <thead>
      <tr>
        <th>Sender</th>
        <th>Title</th>
        <th>Body</th>
        <th>Status</th>
        <th>Date</th>
      </tr>
    </thead>
    <tbody>
      {data.length === 0 ? (
        <tr>
          <td align="center" colSpan={10}>
            No data found
          </td>
        </tr>
      ) : (
        data.map((item, i) => {
          return (
            <tr key={i}>
              <td>
                <User
                  name={item.user[0].username}
                  file_url={item.user[0].file_url}
                />
              </td>
              <td>{item.title}</td>
              <td>{item.body}</td>
              <td>{item.status}</td>
              <td>{format(item.date)}</td>
            </tr>
          );
        })
      )}
    </tbody>
  </table>
);

export default TableNotification;
