import React from "react";
import { Header } from "../components/Header";
import { TableCategory } from "../components/Table";
import api from "../utils/api";
import { URL } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";
import { Loader } from "../components/Loader";
import { connect } from "react-redux";

class CategoryScreen extends React.Component {
  state = {
    data: [1],
    columns: ["Title", "Color"],
  };

  componentDidMount = async () => {
    await this.getProfile();
    this.getCategories(true);
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({ user });
    }
  };

  getCategories() {
    this.setState({
      isLoading: true,
    });
    let data = {
      model: "category",
      token: this.state.user.token,
    };
    let config = {
      method: "post",
      url: URL + "/get_category_info",
      data,
    };

    api(config).then((data) => {
      let categories = [];
      let org_categories = this.get_org_categories(this.props.organization);
      for (let i = 0; i < data.data.categories.length; i++) {
        if (org_categories.includes(data.data.categories[i].title)) {
          categories.push(data.data.categories[i]);
        }
      }

      this.setState({
        data: categories,
        isLoading: false,
      });
    });
  }

  get_org_categories(data) {
    let categories = [];

    if(data.categroy)
      for (let i = 0; i < data.category.length; i++) {
        if (data.category[i].label) {
          categories.push(data.category[i].label);
        } else {
          categories.push(data.category[i]);
        }
      }
    return categories;
  }

  render() {
    return (
      <>
        <Header title="Categories" showAddBtn={false} />
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <TableCategory data={this.state.data} columns={this.state.columns} />
        )}
      </>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization } = state.Organization;
  return {
    organization,
  };
};

export default connect(mapPropsToState)(CategoryScreen);
