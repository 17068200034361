import { combineReducers } from "redux";

import Organization from "./Organization";

const appReducer = combineReducers({
  Organization,
});

const rootReducer = (state, action) => {
  if (action.type === "ON_USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
