import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import DashboardScreen from "./screens/DashboardScreen";
import OrganizationScreen from "./screens/OrganizationScreen";
import ProductScreen from "./screens/ProductScreen";
import CategoryScreen from "./screens/CategoryScreen";
import OrderScreen from "./screens/OrderScreen";
import ProductDetailScreen from "./screens/ProductDetailScreen";
import OrderDetailScreen from "./screens/OrderDetailScreen";
import NewProductScreen from "./screens/NewProductScreen";
import SettingScreen from "./screens/SettingScreen";
import NewOrganizationScreen from "./screens/NewOrganizationScreen";
import NotificationScreen from "./screens/NotificationScreen";
import MessageScreen from "./screens/MessageScreen";
import { socket } from "./utils/socketIo";
import NewShopScreen from "./screens/NewShopScreen";
import ShopScreen from "./screens/ShopScreen";
import BillingTransactionScreen from "./screens/BillingTransactionScreen";

function App() {
  socket.on("connect", () => {
    console.log("connected");
  });

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginScreen} />
        <Route exact path="/register" component={RegisterScreen} />
        <Route exact path="/app" component={DashboardScreen} />
        <DashboardScreen>
          <Route
            exact
            path="/app/organization"
            component={OrganizationScreen}
          />
          <Route
            exact
            path="/app/create_organization"
            component={NewOrganizationScreen}
          />
          <Route
            exact
            path="/app/create_organization/:org_id"
            component={NewOrganizationScreen}
          />
          <Route exact path="/app/products" component={ProductScreen} />
          <Route
            exact
            path="/app/create_product"
            component={NewProductScreen}
          />
          <Route
            exact
            path="/app/create_product/:product_id"
            component={NewProductScreen}
          />
          <Route
            exact
            path="/app/products/:product_id"
            component={ProductDetailScreen}
          />
          <Route exact path="/app/categories/" component={CategoryScreen} />
          <Route exact path="/app/orders/" component={OrderScreen} />
          <Route
            exact
            path="/app/orders/:order_id"
            component={OrderDetailScreen}
          />
          <Route
            exact
            path="/app/notifications"
            component={NotificationScreen}
          />
          <Route exact path="/app/messages" component={MessageScreen} />
          <Route exact path="/app/settings/:tag" component={SettingScreen} />
          <Route exact path="/app/shops" component={ShopScreen} />
          <Route exact path="/app/create_shop" component={NewShopScreen} />
          <Route
            exact
            path="/app/create_shop/:shop_id"
            component={NewShopScreen}
          />
          <Route
            exact
            path="/app/billing_transactions"
            component={BillingTransactionScreen}
          />
        </DashboardScreen>
      </Switch>
    </Router>
  );
}

export default App;
