import React from "react";
import "./styles.css";
import moment from "moment";

const ProductDetail = ({ data, selected_img, onSelectImage }) => (
  <div className="row">
    <div className="col-md-6">
      <div className="">
        <div className="images">
          <div className="main-img card">
            <img
              src={data[0].file_url[selected_img]}
              className="img-responsive"
            />
          </div>
          <div className="small-images pl-1 pr-1 mt-1">
            {data[0].file_url.map((item, i) => {
              return (
                <div
                  key={i}
                  className="image_item mr-1"
                  onClick={() => onSelectImage(i)}
                >
                  <img
                    src={item}
                    className={selected_img === i ? "active" : ""}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Title</td>
                <td>
                  <b>{data[0].title}</b>
                </td>
              </tr>
              <tr>
                <td>Description</td>
                <td>
                  <b>{data[0].description}</b>
                </td>
              </tr>
              <tr>
                <td>Price</td>
                <td>
                  <b>{data[0].currency + " " + data[0].price}</b>
                </td>
              </tr>
              <tr>
                <td>Flag</td>
                <td>
                  <b>{data[0].flag}</b>
                </td>
              </tr>
              <tr>
                <td>Product type</td>
                <td>
                  <b>{data[0].product_type}</b>
                </td>
              </tr>
              <tr>
                <td>Category</td>
                <td>
                  <b>{data[0].category}</b>
                </td>
              </tr>
              <tr>
                <td>Country</td>
                <td>
                  <b>{data[0].country}</b>
                </td>
              </tr>
              <tr>
                <td>Size</td>
                <td>
                  <b>{data[0].size}</b>
                </td>
              </tr>
              <tr>
                <td>Brand</td>
                <td>
                  <b>{data[0].brand}</b>
                </td>
              </tr>
              <tr>
                <td>Mode</td>
                <td>
                  <b>{data[0].mode}</b>
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td>
                  <b>{data[0].status}</b>
                </td>
              </tr>
              <tr>
                <td>Color</td>
                <td>
                  <b>{JSON.stringify(data[0].status)}</b>
                </td>
              </tr>
              <tr>
                <td>Year Manufacturing</td>
                <td>
                  <b>{data[0].year_manufacturing}</b>
                </td>
              </tr>
              <tr>
                <td>Instock</td>
                <td>
                  <b>{data[0].instock}</b>
                </td>
              </tr>
              <tr>
                <td>Discount</td>
                <td>
                  <b>{data[0].discount}</b>
                </td>
              </tr>
              <tr>
                <td>Owner</td>
                <td>
                  <b>
                    <a target="_blank" href={`mailto:${data[0].user[0].email}`}>
                      {data[0].user[0].email
                        ? data[0].user[0].email
                        : data[0].user[0].phone_number}
                    </a>
                  </b>
                </td>
              </tr>
              <tr>
                <td>Date Added</td>
                <td>
                  <b>{moment(data[0].date).format("lll")}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

export default ProductDetail;
