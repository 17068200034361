import React from "react";
import { countries } from "../../utils/CONSTANTS";
import { Input } from "../Form/Input";
import { Select } from "../Form/Select";
import api from "../../utils/api";
import validatePhone from "../../utils/validatePhone";
import { URL } from "../../utils/CONSTANTS";
import { Button } from "../Form/Button";

class PayService extends React.Component {
  state = {
    country: {
      dial_code: "+250",
      label: "RWANDA",
      value: "RWANDA",
    },
    services: [],
    service: {},
    amount: "",
    organization: {},
    error: {},
    phonenumber: "",
    isSubmitting: false,
    message: {},
  };

  componentDidMount() {
    this.getServices(this.state.country.value);
  }

  handleSelect(key, value) {
    delete this.state.error[key];
    this.setState({
      [key]: value,
      error: this.state.error,
    });

    if (key === "country") {
      this.getServices(value.value);
    }
  }

  handleSelectCountry(value) {
    console.log(value);
    delete this.state.error["country"];
    this.setState(
      {
        country: {
          ...value,
        },

        error: this.state.error,
      },
      () => {
        this.getServices(value.value);
      }
    );
  }

  onChangeInput(key, value) {
    delete this.state.error[key];
    this.setState({
      [key]: value.target.value,
      error: this.state.error,
    });
  }

  getServices(country) {
    let data = {
      country,
      model: "service",
    };
    let config = {
      method: "post",
      url: URL + "/get_service_info",
      data,
    };

    api(config)
      .then((data) => {
        let services = [];

        if (data.data.length > 0) {
          this.setState({
            label: data.data[0].service_category,
            value: data.data[0]._id,
            cost: data.data[0].cost,
            currency: data.data[0].currency,
          });
        }

        for (let i = 0; i < data.data.length; i++) {
          services.push({
            label: data.data[i].service_category,
            value: data.data[i]._id,
            cost: data.data[i].cost,
            currency: data.data[i].currency,
          });
        }
        this.setState({
          services,
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  validateForm() {
    const { phonenumber, amount, service, country } = this.state;
    let error = {};

    if (phonenumber === "") {
      error.phonenumber = "Enter phone number";
    }

    if (!service.cost) {
      error.amount = "Amount is required";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
        message: {},
      });

      const { phonenumber, country, service } = this.state;
      const { user, organization } = this.props;
      const options = {
        method: "POST",
        url: URL + "/init_org_transaction_mobile",
        data: {
          phonenumber: country.dial_code + "" + phonenumber,
          amount: service.cost,
          user_id: user.user_id,
          organization: organization._id,
          service: service.value,
          model: "orgtransaction",
        },
      };
      api(options)
        .then((data) => {
          this.state.message.success = data.data.description;
          this.setState({
            isSubmitting: false,
            service: {},
            phonenumber: "",
            message: this.state.message,
          });
        })
        .catch((err) => {
          this.setState({
            isSubmitting: false,
            message: {},
          });
        });
    }
  };

  render() {
    let options = [];

    for (let i = 0; i < countries.length; i++) {
      options.push({
        label: countries[i].name,
        value: countries[i].name,
        dial_code: countries[i].dial_code,
      });
    }
    return (
      <div>
        {this.state.message.success && (
          <div className="alert alert-info">{this.state.message.success}</div>
        )}
        <form>
          <Select
            required
            data={options}
            label="Country"
            label_title="Country"
            isStatic
            value={this.state.country}
            onChange={(e) => this.handleSelect("country", e)}
          />
          <Select
            required
            data={this.state.services}
            label="Service"
            label_title="Service"
            isStatic
            value={this.state.service}
            onChange={(e) => this.handleSelect("service", e)}
          />
          {this.state.service.cost && (
            <Input
              required
              label
              label_title="Amount"
              error={this.state.error.amount}
              value={
                this.state.service.currency + " " + this.state.service.cost
              }
              disabled
            />
          )}
          <div className="row">
            <div className="col-md-3">
              <Input
                label
                label_title="Dial Code"
                value={this.state.country.dial_code}
                disabled
              />
            </div>
            <div className="col-md-9">
              <Input
                required
                label
                label_title="Phone number"
                placeholder="Enter phone number"
                error={this.state.error.phonenumber}
                value={this.state.phonenumber}
                onChange={(e) => this.onChangeInput("phonenumber", e)}
              />
            </div>
          </div>
          <Button
            title="Submit"
            className="btn-primary"
            handlePress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </form>
      </div>
    );
  }
}

export default PayService;
