import React from "react";
import "./styles.css";
import Logo from "../../assets/logo.png";
import { ITEM_IMAGE_PLACEHOLDER } from "../../utils/CONSTANTS";

const Product = ({ title, file_url, img_style }) => (
  <div className="product pl-1 pr-1">
    <div className="p_img mr-1" style={img_style}>
      {file_url.length !== 0 ? (
        <img src={file_url[0]} />
      ) : (
        <img src={ITEM_IMAGE_PLACEHOLDER} />
      )}
    </div>
    <div className="product-details">
      <p className="mb-0">
        <b>{title}</b>
      </p>
    </div>
  </div>
);
export default Product;
