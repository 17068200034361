import React from "react";
import "./styles.css";
import { Input } from "../Form/Input";
import { Button } from "../Form/Button";
import { Link } from "react-router-dom";
import {Alert} from '../Alert'
const Login = ({ email, password, isSubmitting, onSubmit, onChangeInput, error }) => (
	<>
		<div className="card" style={{marginBottom: 'var(--mb-2)'}}>
			<div className="card-body">
				{
					error.success_message && <Alert className="alert-success">{error.success_message}</Alert>
				}
				{
					error.error_message && <Alert className="alert-danger">{error.error_message}</Alert>
				}
				<form>
					<Input
						type="email"
						label={true}
						label_title="Email address"
						value={email}
						onChange={e => onChangeInput(e, "email")}
						error={error.email}
					/>
					<Input
						type="password"
						label={true}
						label_title="Enter password"
						value={password}
						onChange={e => onChangeInput(e, "password")}
						error={error.password}
					/>
					<Button 
						className="btn-primary btn-block" 
						title="Sign in" 
						isSubmitting={isSubmitting}
						handlePress={onSubmit}
					/>
				</form>
			</div>
		</div>
		<div className="card">
			<div className="card-body">
				<b>Don't have an account? <Link to="/register">Register</Link></b>
			</div>
		</div>
	</>
);
export default Login;
