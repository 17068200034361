import React from "react";
import { AppContent } from "../components/AppContent";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import { DefaultOrganization } from "../components/DefaultOrganization";
import { getStorage } from "../utils/sessionStorage";
import { connect } from "react-redux";
import { onSetOrganization } from "../action/Organization";

class DashboardScreen extends React.Component {
  state = { user: {}, active: "", isToggled: false };

  componentDidMount = async () => {
    await this.getProfile();
    this.setActiveSideItem(this.props.location.pathname);
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({ user });
    } else {
      this.gotoLogin();
    }
  };

  gotoLogin() {
    window.location.href = "/";
  }

  onChangeOrg(e) {
    let index = e.target[e.target.selectedIndex].getAttribute("data-item");
    let item = this.props.organizations[index];
    this.props.dispatch(onSetOrganization(item));
    window.location.reload();
  }

  componentWillUpdate(nxtProps) {
    if (this.props.location.pathname !== nxtProps.location.pathname) {
      this.setActiveSideItem(nxtProps.location.pathname);
    }
  }

  setActiveSideItem(pathname) {
    this.setState({
      active: pathname,
    });
  }

  onToggleMenu() {
    this.setState({
      isToggled: !this.state.isToggled,
    });
  }

  render() {
    // console.log(this.props);
    return (
      <div className="container-fluid pl-0 pr-0">
        <Navbar
          user={this.state.user}
          onToggleMenu={this.onToggleMenu.bind(this)}
        />
        <div
          className=" ml-0 mr-0"
          style={{ display: "flex", height: "100vh" }}
        >
          <div className={this.state.isToggled ? "pl-0 pr-0" : " pl-0 pr-0"}>
            <Sidebar
              active={this.state.active}
              isToggled={this.state.isToggled}
            />
          </div>
          <AppContent>
            {this.props.location.hash !== "no_o" &&
              this.props.organizations.length !== 0 && (
                <DefaultOrganization
                  onChangeOrg={this.onChangeOrg.bind(this)}
                  organization={this.props.organization}
                  organizations={this.props.organizations}
                />
              )}
            <div className="table-responsive">{this.props.children}</div>
          </AppContent>
        </div>
      </div>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization, organizations } = state.Organization;
  return {
    organization,
    organizations,
  };
};

export default connect(mapPropsToState)(DashboardScreen);
