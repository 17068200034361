import React from "react";
import { Header } from "../components/Header";
import { TableNotification } from "../components/Table";
import api from "../utils/api";
import { URL } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";
import { Loader } from "../components/Loader";
import { connect } from "react-redux";
import { onSetOrganization, onFetchOrganization } from "../action/Organization";
import { Filter } from "../components/Filter";

class NotificationScreen extends React.Component {
  state = {
    data: [],
    data: [],
    isLoading: true,
    user: {},
  };

  componentDidMount = async () => {
    await this.getProfile();
    this.getData(true, this.state.user);
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({ user });
    } else {
      this.gotoLogin();
    }
  };

  gotoLogin() {
    window.location.href = "/";
  }

  getData(isLoading) {
    this.setState({ isLoading, data: [] });

    let data = {
      model: "notification",
      user: [this.state.user.user_id],
      organization: [this.props.organization._id],
    };
    let config = {
      method: "post",
      url: URL + "/get_notification",
      data,
    };

    api(config)
      .then((data) => {
        this.setState({
          isLoading: false,
          data: data.data,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handlePress(item) {
    this.props.dispatch(onSetOrganization(item));
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <TableNotification data={this.state.data} showCard={false} />
          </>
        )}
      </>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization } = state.Organization;
  return {
    organization,
  };
};

export default connect(mapPropsToState)(NotificationScreen);
