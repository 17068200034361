import React from "react";
import { Button } from "../Form/Button";
import { Input } from "../Form/Input";
import { Select } from "../Form/Select";
import "./styles.css";
import { Alert } from "../Alert";

const NewProduct = ({
  onChangeInput,
  error,
  onChangeCategory,
  onChangeSubCategory,
  onChangeImages,
  onChangeColors,
  onSubmit,
  product_name,
  sub_categories,
  price,
  made,
  brand,
  description,
  discount,
  in_stock,
  style,
  year_manufacturing,
  size_to,
  size_from,
  address,
  section,
  colors,
  shop_name,
  category,
  flag,
  currency,
  categories,
  countries,
  country,
  sub_category,
  color,
  status,
  isSubmitting,
}) => (
  <div className="row">
    <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          {error.success_message && (
            <Alert className="alert-success">{error.success_message}</Alert>
          )}
          {error.error_message && (
            <Alert className="alert-danger"> {error.error_message}</Alert>
          )}
          <form>
            <div className="row">
              <div className="col-md-6">
                <Input
                  label={true}
                  label_title="Product name:"
                  value={product_name}
                  onChange={(e) => onChangeInput(e, "product_name")}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={true}
                  label_title="Product description:"
                  value={description}
                  onChange={(e) => onChangeInput(e, "description")}
                />
              </div>
              <div className="col-md-6">
                <Select
                  data={["local", "international"]}
                  label={true}
                  label_title="Flag:"
                  value={flag}
                  onChange={(e) => onChangeInput(e, "flag")}
                />
              </div>
              <div className="col-md-6">
                <Select
                  data={["RWF", "USD"]}
                  label={true}
                  label_title="Currency:"
                  value={currency}
                  onChange={(e) => onChangeInput(e, "currency")}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="number"
                  label={true}
                  label_title="Price"
                  value={price}
                  onChange={(e) => onChangeInput(e, "price")}
                />
              </div>
              <div className="col-md-6">
                <Select
                  data={categories}
                  data_key="title"
                  label={true}
                  label_title="Category:"
                  value={category}
                  onChange={onChangeCategory}
                />
              </div>
              <div className="col-md-6">
                <Select
                  data={sub_categories}
                  data_key="name"
                  label={true}
                  label_title="Sub Category:"
                  value={sub_category}
                  onChange={onChangeSubCategory}
                />
              </div>
              <div className="col-md-6">
                {category === "Shop & Restaurant" && (
                  <>
                    <Input
                      label={true}
                      label_title="Shop name"
                      value={shop_name}
                      onChange={(e) => onChangeInput(e, "shop_name")}
                    />
                    <Input
                      label={true}
                      label_title="Section"
                      value={section}
                      onChange={(e) => onChangeInput(e, "section")}
                    />
                  </>
                )}
              </div>
              <div className="col-md-6">
                <Select
                  data={countries}
                  data_key="name"
                  label={true}
                  label_title="Country:"
                  value={country}
                  onChange={(e) => onChangeInput(e, "country")}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={true}
                  label_title="Store Address"
                  value={address}
                  onChange={(e) => onChangeInput(e, "address")}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={true}
                  label_title="Discount"
                  value={discount}
                  onChange={(e) => onChangeInput(e, "discount")}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={true}
                  type="number"
                  label_title="In stock"
                  value={in_stock}
                  onChange={(e) => onChangeInput(e, "in_stock")}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={true}
                  label_title="Brand"
                  value={brand}
                  onChange={(e) => onChangeInput(e, "brand")}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={true}
                  label_title="Made"
                  value={made}
                  onChange={(e) => onChangeInput(e, "made")}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={true}
                  label_title="style"
                  value={style}
                  onChange={(e) => onChangeInput(e, "style")}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={true}
                  type="number"
                  label_title="year_manufacturing"
                  value={year_manufacturing}
                  onChange={(e) => onChangeInput(e, "year_manufacturing")}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={true}
                  type="number"
                  label_title="size From"
                  value={size_from}
                  onChange={(e) => onChangeInput(e, "size_from")}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={true}
                  type="number"
                  label_title="size To"
                  value={size_to}
                  onChange={(e) => onChangeInput(e, "size_to")}
                />
              </div>
              <div className="col-md-6">
                <Select
                  data={colors}
                  label={true}
                  label_title="Colors:"
                  isCreatable
                  value={color}
                  onChange={onChangeColors}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={true}
                  label_title="Status"
                  value={status}
                  onChange={(e) => onChangeInput(e, "status")}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          <form>
            <Input
              type="file"
              label={true}
              label_title="Upload Images"
              onChange={onChangeImages}
            />
          </form>
        </div>
      </div>
    </div>
    <div className="col-md-12 mt-1">
      <div className="card">
        <div className="card-body">
          <Button
            title="Submit"
            className="btn-primary"
            isSubmitting={isSubmitting}
            handlePress={onSubmit}
          />
        </div>
      </div>
    </div>
  </div>
);

export default NewProduct;
