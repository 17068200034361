import React from "react";
import { Header } from "../components/Header";
import { TableOrg, TableOrgTransaction } from "../components/Table";
import api from "../utils/api";
import { URL } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";
import { Loader } from "../components/Loader";
import { connect } from "react-redux";
import { onSetOrganization, onFetchOrganization } from "../action/Organization";
import { Filter } from "../components/Filter";
import { Modal } from "../components/Modal";
import NewOrganizationScreen from "./NewOrganizationScreen";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from "../components/TabPanel";
import { PayService } from "../components/PayService";

class OrganizationScreen extends React.Component {
  state = {
    data: [],
    rows: ["name"],
    isLoading: true,
    user: {},
    show_modal: false,
    tabValue: 0,
    services: [],
    isLoadingTransaction: false,
    transactions: [],
    show_payment_modal: false,
    isAdmin:false,
  };

  componentDidMount = async () => {
    await this.getProfile();
    this.getData(true, this.state.user);
    this.getTransactions();
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.user_id) {
      console.log("admin: ", user)
      this.setState({ user , isAdmin:user.isAdmin});
    } else {
      this.gotoLogin();
    }
  };

  gotoLogin() {
    window.location.href = "/";
  }

  getData(isLoading) {
    this.setState({ isLoading });

    let data = {
      model: "organization",
      user: [this.state.user.user_id],
      tag: "dashboard",
    };
    let config = {
      method: "post",
      url: URL + "/get_organization_info",
      data,
    };

    api(config)
      .then((data) => {
        this.setState({
          isLoading: false,
          data: data.data,
        });

        if (data.data.length !== 0) {
          if (!sessionStorage.getItem("organization")) {
            this.props.dispatch(onSetOrganization(data.data[0]));
          }
          this.props.dispatch(onFetchOrganization(data.data));
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  getTransactions() {
    const { organization } = this.props;
    const { user } = this.state;

    if (!organization._id) return;
    if (!user.token) return;

    this.setState({
      isLoadingTransaction: true,
    });

    let data = {
      organization: organization._id,
      model: "orgtransaction",
      token: user.token,
    };
    let config = {
      method: "post",
      url: URL + "/get_transaction_info",
      data,
    };

    api(config)
      .then((data) => {
        console.log(data.data);
        this.setState({
          transactions: data.data,
          isLoadingTransaction: false,
        });
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({
          isLoadingTransaction: false,
        });
      });
  }

  handlePress(item) {
    this.props.dispatch(onSetOrganization(item));
    window.location.reload();
  }

  openModal(selected_org) {
    this.setState({
      show_modal: true,
      selected_org,
    });
  }

  close_modal() {
    this.setState({
      show_modal: false,
      show_payment_modal: false,
    });
  }

  onDelete(item, index) {
    if (window.confirm("Are you sure, you want to delete this item?")) {
      this.state.data.splice(index, 1);
      this.setState({ data: this.state.data });

      this.delete_remote_data(item);
    }
  }

  delete_remote_data(data) {
    let formData = new FormData();
    formData.append("model", "organization");
    formData.append("available", false);
    formData.append("id", data._id);
    formData.append("token", this.state.user.token);
    let config = {
      method: "POST",
      url: URL + "/update_organization_info",
      data: formData,
    };

    api(config)
      .then((data) => {
        this.getData(false);
      })
      .catch((err) => console.log(err));
  }

  handleChange = (event, tabValue) => {
    this.setState({
      tabValue,
    });
  };

  handlePressPayModal() {
    this.setState({
      show_payment_modal: true,
    });
  }

  render() {
    return (
      <>
        <Header
          title={this.state.tabValue === 0 ? "Organizations" : "Make Payment"}
          showAddBtn={true}
          route="/app/create_organization"
          isLink={this.state.tabValue === 0}
          handlePress={this.handlePressPayModal.bind(this)}
        />
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <Filter />
            <TableOrg
              data={this.state.data}
              handlePress={this.handlePress.bind(this)}
              showCard={false}
              openModal={this.openModal.bind(this)}
              onDelete={this.onDelete.bind(this)}
            />
          </>
        )}
        <Modal
          show={this.state.show_modal}
          handleClose={this.close_modal.bind(this)}
          size="lg"
          centered
        >
          <NewOrganizationScreen data={this.state.selected_org} isAdmin={this.state.isAdmin} />
        </Modal>
        <Modal
          show={this.state.show_payment_modal}
          handleClose={this.close_modal.bind(this)}
          // size="lg"
          centered
          title="Pay Service"
        >
          <PayService
            user={this.state.user}
            organization={this.props.organization}
          />
        </Modal>
      </>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization } = state.Organization;
  return {
    organization,
  };
};

export default connect(mapPropsToState)(OrganizationScreen);
