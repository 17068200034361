import React from "react";
import { connect } from "react-redux";
import { Header } from "../components/Header";
import { Loader } from "../components/Loader";
import { NewProduct } from "../components/NewProduct";
import api from "../utils/api";
import { URL, countries } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";

class NewProductScreen extends React.Component {
  state = {
    product_name: "",
    category: "",
    shop_name: "",
    sub_categories: [],
    sub_category: "",
    category_index: "",
    currency: "RW",
    flag: "local",
    price: "",
    made: "",
    brand: "",
    description: "",
    discount: "",
    in_stock: "",
    style: "",
    year_manufacturing: "",
    size_to: "",
    size_from: "",
    product_status_date: "",
    product_status: "",
    address: "",
    section: "",
    images: [],
    colors: [],
    isSubmitting: false,
    user: {},
    error: {},
    categories: [],
    isLoading: false,
    remote_file: [],
    product_id: "",
    organization: [],
    status: "",
  };

  componentDidMount = async () => {
    await this.getProfile();
    this.getCategories(true);

    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.product_id
    ) {
      this.setState({
        isLoading: true,
        product_id: this.props.match.params.product_id,
      });
      this.getData(true, this.props.match.params.product_id);
    }
  };

  getData(isLoading, _id) {
    this.setState({ isLoading });
    let data = {
      model: "product",
      id: _id,
      token: this.state.user.token,
    };
    let config = {
      method: "post",
      url: URL + "/get_product_info",
      data,
    };

    api(config)
      .then((data) => {
        const {
          address,
          available,
          brand,
          category,
          colors,
          country,
          currency,
          description,
          discount,
          file,
          file_url,
          flag,
          instock,
          lat,
          likes,
          long,
          made,
          organization,
          price,
          product_type,
          reporter,
          section,
          shop_name,
          size,
          start_selling,
          status,
          style,
          title,
          year_manufacturing,
          _id,
        } = data.data[0];

        for (let i = 0; i < this.state.categories.length; i++) {
          if (this.state.categories[i].title === category) {
            this.setState({
              sub_categories: this.state.categories[i].data,
            });
            break
          }
        }

        let size_from = "",
          size_to = "";
        if (size !== "") {
          let size_array = size.split("-");
          size_from = size_array.length > 0 ? size_array[0] : "";
          size_to = size_array.length > 1 ? size_array[1] : "";
        }

        this.setState({
          isLoading: false,
          address,
          available,
          brand,
          category,
          sub_category:product_type,
          colors,
          country,
          currency,
          description,
          discount: discount ? discount : 0,
          remote_file: file,
          file_url,
          flag,
          in_stock: instock ? instock : "",
          lat,
          likes,
          long,
          made,
          organization: organization[0],
          price,
          reporter,
          section,
          shop_name,
          size_from,
          size_to,
          start_selling,
          product_status: status,
          style,
          product_name: title,
          year_manufacturing,
          product_id: _id,
        });
        // console.log({ size_from, size_to });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({ user });
    }
  };

  getCategories() {
    let data = {
      model: "category",
      token: this.state.user.token,
    };
    let config = {
      method: "post",
      url: URL + "/get_category_info",
      data,
    };

    api(config).then((data) => {
      this.setState({
        categories: data.data.categories,
      });
    });
  }

  onChangeCategory(e) {
    this.setState({
      category: e.target.value,
    });

    for (let i = 0; i < this.state.categories.length; i++) {
      if (this.state.categories[i].title === e.target.value) {

        this.setState({
          sub_categories: this.state.categories[i].data,
        });
        break
      }
    }
  }

  onChangeSubCategory(e) {
    this.setState({
      sub_category: e.target.value,
    });
  }


  onChangeProductStatusDate(e) {
    this.setState({
      product_status_date: e.target.value,
    });
  }

  onChangeColors = (newValue) => {
    // console.log(newValue);
  };

  onChangeImages(e) {
    let images = [];
    for (var i = 0; i < e.target.files.length; i++) {
      images[i] = e.target.files.item(i);
    }
    images = images.filter((image) =>
      image.name.match(/\.(jpg|jpeg|png|gif)$/)
    );
    let message = `${images.length} valid image(s) selected`;
    this.setState({ images, message });
  }

  onChangeInput(e, key) {
    this.setState({
      [key]: e.target.value,
      error: {},
    });
  }

  onSubmit() {
    const {
      product_name,
      currency,
      price,
      made,
      brand,
      description,
      discount,
      in_stock,
      style,
      year_manufacturing,
      size_to,
      size_from,
      product_status_date,
      product_status,
      address,
      images,
      category,
      sub_category,
      shop_name,
      country,
      colors,
      flag,
      section,
      product_id,
      status,
    } = this.state;

    if (product_name === "") {
      alert("Product name is required");
    } else if (price === "") {
      alert("price is required");
    } else if (address === "") {
      alert("Address is required");
    } else if (images.length === 0 && product_id === "") {
      alert("Please upload at lease 1 product image");
    } else if (!this.props.organization._id) {
      alert("Organization is missing");
    } else {
      this.setState({
        isSubmitting: true,
      });
      let new_color = [];
      if (colors.length !== 0) {
        for (let i = 0; i < colors.length; i++) {
          if (!new_color.includes(colors[i].value)) {
            new_color.push(colors[i].value);
          }
        }
      }

      const { user } = this.state;
      let formData = new FormData();
      console.log("sub category: ", sub_category)

      formData.append("model", "product");
      formData.append("title", product_name);
      formData.append("user", '["' + user.user_id + '"]');
      formData.append("token", user.token);
      formData.append("colors", JSON.stringify(colors));
      formData.append("description", description);
      formData.append("category", category);
      formData.append("price", parseFloat(price));
      formData.append("currency", currency);
      formData.append("country", country);
      formData.append("brand", brand);
      formData.append("instock", in_stock);
      formData.append("discount", discount);
      formData.append("status", product_status);
      formData.append("start_selling", product_status_date);
      sub_category && sub_category !=="" && formData.append("product_type", sub_category);
      formData.append("size", size_from + "-" + size_to);
      formData.append("lat", "0");
      formData.append("long", "0");
      formData.append("shop_name", shop_name);
      formData.append("address", address);
      formData.append("section", section);
      formData.append("available", true);
      formData.append("likes", 0);
      formData.append("year_manufacturing", year_manufacturing);
      formData.append("made", made);
      formData.append("made", status);
      formData.append("flag", flag);
      formData.append("style", style);
      formData.append("date", new Date().getTime());
      if (product_id !== "") {
        formData.append(
          "organization",
          '["' + this.state.organization._id + '"]'
        );
      } else {
        formData.append(
          "organization",
          '["' + this.props.organization._id + '"]'
        );
      }

      images.forEach((element, i) => {
        formData.append(("file" + i).replace(/\s/g, ""), element);
      });

      let url = URL + "/add_product_info";

      if (this.state.product_id && this.state.product_id !== "") {
        formData.append("id", this.state.product_id);
        url = URL + "/update_product_info";
      }

      let config = {
        method: "post",
        url,
        data: formData,
        headers: {
          "content-type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };

      api(config)
        .then((data) => {
          this.state.error.success_message =
            product_id !== ""
              ? "Data updated successfully"
              : "Data added successfully";

          this.setState({
            isSubmitting: false,
            error: this.state.error,
            address: "",
            brand: "",
            category: "",
            colors: [],
            country: "",
            currency: "",
            description: "",
            discount: 0,
            remote_file: [],
            file_url: [],
            flag: "",
            in_stock: "",
            lat: "",
            long: "",
            made: "",
            price: "",
            section: "",
            shop_name: "",
            size_from: "",
            size_to: "",
            product_status: "",
            style: "",
            product_name: "",
            year_manufacturing: "",
          });

          if (this.state.product_id !== "") {
            this.getData(true, this.state.product_id);
          }
        })
        .catch((err) => {
          if (err.response) {
            this.state.error.error_message = err.response.data.message;
          }
          this.setState({
            isSubmitting: false,
            error: this.state.error,
          });
        });
    }
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <Header
              title={this.state.product_id ? "Edit Product" : "Create Product"}
              showAddBtn={false}
            />
            <NewProduct
              error={this.state.error}
              onChangeInput={this.onChangeInput.bind(this)}
              onChangeCategory={this.onChangeCategory.bind(this)}
              onChangeSubCategory={this.onChangeSubCategory.bind(this)}
              onChangeImages={this.onChangeImages.bind(this)}
              onChangeColors={this.onChangeColors.bind(this)}
              categories={this.state.categories}
              countries={countries}
              product_name={this.state.product_name}
              flag={this.state.flag}
              style={this.state.style}
              made={this.state.made}
              brand={this.state.brand}
              currency={this.state.currency}
              price={this.state.price}
              size={this.state.size}
              size_from={this.state.size_from}
              size_to={this.state.size_to}
              year_manufacturing={this.state.year_manufacturing}
              in_stock={this.state.in_stock}
              country={this.state.country}
              discount={this.state.discount}
              product_type={this.state.product_type}
              address={this.state.address}
              description={this.state.description}
              category={this.state.category}
              sub_category={this.state.sub_category}
              colors={this.state.colors}
              sub_categories={this.state.sub_categories}
              status={this.state.status}
              isSubmitting={this.state.isSubmitting}
              onSubmit={this.onSubmit.bind(this)}
            />
          </>
        )}
      </>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization } = state.Organization;
  // console.log(organization);
  return {
    organization,
  };
};

export default connect(mapPropsToState)(NewProductScreen);
