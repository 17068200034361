import React from "react";
import "./styles.css";
import { USER_IMAGE_PLACEHOLDER } from "../../utils/CONSTANTS";

const User = ({ name, file_url, img_style }) => (
  <div className="user pl-1 pr-1">
    <div className="user_img mr-1" style={img_style}>
      {file_url.length !== 0 ? (
        <img src={file_url[0]} />
      ) : (
        <img src={USER_IMAGE_PLACEHOLDER} />
      )}
    </div>
    <div className="user-details">
      <p className="mb-0">
        <b>{name}</b>
      </p>
    </div>
  </div>
);
export default User;
