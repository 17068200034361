import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
const Navbar = ({user, onToggleMenu}) => (
	<nav className="navbar navbar-expand-lg navbar-light bg-light">
		<div className="container-fluid">
			<Link className="navbar-brand" href="#" onClick={onToggleMenu}>
				<div className="menu mr-1">
					<i className="fa fa-bars"/>
				</div>
			</Link>
			<div>
				<ul className="navbar-nav ml-auto">
					<li className="nav-item dropdown">
						<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<div className="prof_placeholder mr-1">{user.username && user.username.charAt(0)}</div>{user.username}
						</a>
						<div className="dropdown-menu" aria-labelledby="navbarDropdown">
							<Link to="/"><i className="fa fa-sign-out-alt"/> Logout</Link>
						</div>
					</li>
				</ul>
			</div>	
		</div>
	</nav>
);
export default Navbar;
