import React from "react";
import { connect } from "react-redux";
import { Filter } from "../components/Filter";
import { Header } from "../components/Header";
import { Loader } from "../components/Loader";
import { TableProduct } from "../components/Table";
import api from "../utils/api";
import { URL } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";

let copyData = [];

class ProductScreen extends React.Component {
  state = {
    data: [],
    columns: [
      "Product",
      "Price",
      "Flag",
      "Product Type",
      "Category",
      // "Actions",
    ],
    search_text: "",
    country: "",
    category: "",
    flag:""
  };

  componentDidMount = async () => {
    await this.getProfile();
    this.getData(true, this.state.user);
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({ user });
    }
  };

  getData(isLoading) {
    this.setState({ isLoading, data: [] });

    let data = {
      model: "product",
      organization: [this.props.organization._id],
      token: this.state.user.token,
      user_id: this.state.user.user_id,
      country: this.state.country !== "" ? this.state.country : undefined,
      flag: this.state.flag !== "" ? this.state.flag : undefined,
      category: this.state.category !== "" && this.state.category,
      tag: "dashboard",
    };
    let config = {
      method: "post",
      url: URL + "/get_product_info",
      data,
    };

    api(config)
      .then((data) => {
        this.setState({
          isLoading: false,
          data: data.data,
        });
        copyData = data.data.slice(0);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleSearch(e) {
    let new_array = [],
      text = e.target.value.toUpperCase();

    for (let i = 0; i < copyData.length; i++) {
      if (copyData[i].title.toUpperCase().indexOf(text) !== -1) {
        new_array.push(copyData[i]);
      }
    }

    this.setState({
      data: new_array,
      search_text: e.target.value,
    });
  }

  onChangeCountry(e) {
    let country = e.target.value.toUpperCase();

    this.setState(
      {
        country: country,
      },
      () => {
        this.getData(true);
      }
    );
  }

  onChangeFlag(e) {
    let flag = e.target.value.toLowerCase();

    this.setState(
      {
        flag: flag,
      },
      () => {
        this.getData(true);
      }
    );
  }

  onChangeCategory(e) {
    let category = e.target.value;
    this.setState(
      {
        category: category,
      },
      () => {
        this.getData(true);
      }
    );
  }

  render() {
    return (
      <>
        <Header
          title="Products"
          showAddBtn={true}
          isLink={true}
          route="/app/create_product"
        />
        <Filter
          search_text={this.state.search_text}
          onChange={this.handleSearch.bind(this)}
          showCountry
          showFlag
          onChangeCountry={this.onChangeCountry.bind(this)}
          onChangeFlag={this.onChangeFlag.bind(this)}
          onChangeCategory={this.onChangeCategory.bind(this)}
          category={this.state.category}
          country={this.state.country}
          showCategory
        />
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <TableProduct data={this.state.data} columns={this.state.columns} />
        )}
      </>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization } = state.Organization;
  return {
    organization,
  };
};

export default connect(mapPropsToState)(ProductScreen);
