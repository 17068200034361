import React from "react";
import "./styles.css";

const Input = ({
  placeholder,
  message,
  type,
  value,
  onChange,
  label,
  label_title,
  error,
  className,
  required,
  disabled,
}) => (
  <div className="form-group">
    {label && (
      <label>
        {label_title}
        {required ? <span className="required">*</span> : ""}:
      </label>
    )}
    <input
      type={type}
      value={value}
      onChange={onChange}
      className={`form-control ${className}`}
      placeholder={placeholder}
      disabled={disabled}
    />
    {message && <small className="form-text">{message}</small>}
    {error && <small className="form-text text-danger">{error}</small>}
  </div>
);

export default Input;
