import React from "react";
import { connect } from "react-redux";
// import { onFetchOrganization, onSetOrganization } from "../action/Organization";
import { Header } from "../components/Header";
import { NewOrg } from "../components/NewOrg";
import api from "../utils/api";
import { URL } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";

class NewProductScreen extends React.Component {
  state = {
    user: {},
    isSubmitting: false,
    error: {},
    name: "",
    address: "",
    images: [],
    category: [],
    title: "Create Organization",
    success_message: "",
    isAirtelmomo: false,
    isMTNmomo: false,
    mobilepaycode_mtn: "",
    mobilepaycode_airtel: "",
    provider_mtn: "",
    provider_airtel: "",
    isActivated:false,
    isAdmin:false,
  };

  componentDidMount = async () => {
    await this.getProfile();
    this.getCategories(this.state.user);

    this.checkIsEdit(this.props.data, this.props.isAdmin);
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({ user });
    }
  };

  checkIsEdit(data, isAdmin) {
    if (data && data._id) {
      this.setState({
        name: data.name,
        address: data.address,
        images: [],
        category: this.formatted_category(data.category),
        title: "Update Organization",
        org_id: data._id,
        mobilepaycode_mtn: data.mobilepaycode_mtn,
        mobilepaycode_airtel: data.mobilepaycode_airtel,
        provider_mtn: data.provider_mtn,
        provider_airtel: data.provider_airtel,
        isActivated: data.eshop_activated,
        isAdmin:isAdmin,
      });
    }
  }

  formatted_category(category) {
    let categories = [];
    for (let i = 0; i < category.length; i++) {
      if (category[i].label) {
        categories.push({
          label: category[i].label,
          value: category[i].label,
        });
      } else {
        categories.push({
          label: category[i],
          value: category[i],
        });
      }
    }
    return categories;
  }

  getCategories(user) {
    let data = {
      model: "category",
      token: user.token,
    };
    let config = {
      method: "post",
      url: URL + "/get_category_info",
      data,
    };

    api(config).then((data) => {
      let categories = [];
      for (let i = 0; i < data.data.categories.length; i++) {
        categories.push({
          label: data.data.categories[i].title,
          value: data.data.categories[i].title,
        });
      }
      this.setState({
        categories,
      });
    });
  }

  onChangeImages(e) {
    let images = [];
    for (var i = 0; i < e.target.files.length; i++) {
      images[i] = e.target.files.item(i);
    }
    images = images.filter((image) =>
      image.name.match(/\.(jpg|jpeg|png|gif)$/)
    );
    let message = `${images.length} valid image(s) selected`;
    this.setState({ images, message });
  }

  onChangeInput(e, key) {
    this.setState({
      [key]: e.target.value,
      error: {},
    });
  }

  onChangeCategory(e) {
    this.setState({
      category: e,
    });
  }

  validateForm() {
    let isValid = true;

    if (this.state.name === "") {
      // this.state.error.name = "Name is required"; // bad practice - do not mutate state directly
      let error={}; error["name"] = "Name is required"
      this.setState({ error:error});

      isValid = false;
    }

    if (this.state.address === "") {
      // this.state.error.address = "Address is required";
      let error={}; error["address"] = "Address is required" // bad practice - do not mutate state directly
      this.setState({ error: error });

      isValid = false;
    }

    if (!this.state.category) {
      // this.state.error.category = "Category is required";
      let error={}; error["category"] = "Category is required" // bad practice - do not mutate state directly
      this.setState({ error: this.state.error });

      isValid = false;
    } else if (this.state.category && this.state.category.length === 0) {
      // this.state.error.category = "Category is required";
      let error={}; error["category"] = "Category is required" // bad practice - do not mutate state directly
      this.setState({ error: this.state.error });

      isValid = false;
    }

    return isValid;
  }

  onSubmit() {
    const {
      user,
      name,
      category,
      images,
      address,
      mobilepaycode_airtel,
      provider_airtel,
      mobilepaycode_mtn,
      provider_mtn,
      isMTNmomo,
      isAirtelmomo,
      isActivated,
    } = this.state;
    if (this.validateForm()) {
      this.setState({
        isSubmitting: true,
      });

      let formData = new FormData();

      formData.append("model", "organization");

      formData.append("token", user.token);

      if (isMTNmomo) {
        formData.append("mobilepaycode_mtn", mobilepaycode_mtn);
      }
      if (isAirtelmomo) {
        formData.append("mobilepaycode_airtel", mobilepaycode_airtel);
      }
      if (isActivated===true || isActivated===false) {
        formData.append("eshop_activated", isActivated);
      }

      formData.append("user", JSON.stringify([user.user_id]));

      let _category = [];

      for (let i = 0; i < category.length; i++) {
        _category.push(category[i].value);
      }

      formData.append("category", JSON.stringify(_category));

      images.forEach((element, i) => {
        formData.append(("file" + i).replace(/\s/g, ""), element);
      });

      let route = URL + "/add_organization_info";

      if (this.state.org_id) {
        route = URL + "/update_organization_info";
        formData.append("id", this.state.org_id);
      } else {
        formData.append("name", name);
        formData.append("address", address);
      }

      let config = {
        method: "post",
        url: route,
        data: formData,
        headers: {
          "content-type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };

      api(config)
        .then((data) => {
          let error={}; error["success_message"] =  this.state.org_id
            ? "Data updated successfully"
            : "Data added successfully";

          this.setState({
            isSubmitting: false,
            error: error,
            name: "",
            address: "",
            images: [],
            category: [],
            show_modal: false,
          });
        })
        .catch((err) => {
          let error={}; error["error_message"] =  err.response? err.response.data.message : "Something went wrong";
          this.setState({
            isSubmitting: false,
            error: error,
          });
        });
    }
  }

  onChangeCheckbox(e, key) {
    this.setState({
      [key]: e.target.checked,
    });
  }

  render() {
    return (
      <>
        <Header title={this.state.title} showAddBtn={false} />
        <NewOrg
          error={this.state.error}
          onChangeInput={this.onChangeInput.bind(this)}
          onChangeImages={this.onChangeImages.bind(this)}
          onChangeCategory={this.onChangeCategory.bind(this)}
          onSubmit={this.onSubmit.bind(this)}
          address={this.state.address}
          name={this.state.name}
          isSubmitting={this.state.isSubmitting}
          categories={this.state.categories}
          category={this.state.category}
          onChangeCheckbox={this.onChangeCheckbox.bind(this)}
          isMTNmomo={this.state.isMTNmomo}
          isAirtelmomo={this.state.isAirtelmomo}
          mobilepaycode_airtel={this.state.mobilepaycode_airtel}
          mobilepaycode_mtn={this.state.mobilepaycode_mtn}
          isActivated={this.state.isActivated}
          isAdmin={this.state.isAdmin}
        />
      </>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization, organizations } = state.Organization;
  return {
    organization,
    organizations,
  };
};

export default connect(mapPropsToState)(NewProductScreen);
