import React from "react";
import "./styles.css";
import { Icon } from "../Icon";
import { Link } from "react-router-dom";
const List = ({ icon, title, active, route }) => (
  <Link to={route} style={{ color: "black" }}>
    <li className={`${active ? "active" : ""} list-group-item`}>
      <div className="child_item">
        <div className="icon">
          <Icon name={icon} />
        </div>
        <span>{title}</span>
      </div>
    </li>
  </Link>
);
export default List;
