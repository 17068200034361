import React from "react";
import { TableActionButton } from ".";
import { ITEM_IMAGE_PLACEHOLDER } from "../../utils/CONSTANTS";
import { Product } from "../Product";
import "./styles.css";

const TableOrg = ({ className, data, columns, onEdit, onDelete }) => (
  <table className={`table table-hover ${className}`}>
    <thead>
      <tr>
        {columns.map((item, i) => {
          return <th key={i}>{item}</th>;
        })}
      </tr>
    </thead>
    <tbody>
      {data.length === 0 ? (
        <tr>
          <td align="center" colSpan={10}>
            No data found
          </td>
        </tr>
      ) : (
        data.map((item, i) => {
          return (
            <tr key={i}>
              <td>
                <Product
                  title={item.shop_name}
                  file_url={
                    item.shop_url ? [item.shop_url] : [ITEM_IMAGE_PLACEHOLDER]
                  }
                />
              </td>
              <td>
                <b>{item.shop_name}</b>
              </td>
              <td>
                <b>{item.shop_type}</b>
              </td>
              <td>{item.section}</td>
              <td>
                <TableActionButton
                  show_delete
                  show_edit
                  onDelete={() => onDelete(item, i)}
                  onEdit={() => onEdit(item)}
                />
              </td>
            </tr>
          );
        })
      )}
    </tbody>
  </table>
);

export default TableOrg;
