import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { User } from "../User";

const TableOrg = ({ className, data, handlePress, columns, showCard }) => (
  <table className={`table table-hover ${className}`}>
    <thead>
      <tr>
        {columns.map((item, i) => {
          return <th key={i}>{item}</th>;
        })}
      </tr>
    </thead>
    <tbody>
      {data.length === 0 ? (
        <tr>
          <td align="center" colSpan={10}>
            No data found
          </td>
        </tr>
      ) : (
        data.map((item, i) => {
          return (
            <tr key={i} onClick={() => handlePress(item)}>
              <td>
                <User file_url={item.file} name={item.username} />
              </td>
              <td>
                <a href href="">
                  <b>{item.email}</b>
                </a>
              </td>
              <td>
                <a href href="">
                  <b>{item.phone_number}</b>
                </a>
              </td>
              <td>
                <b>{item.tag}</b>
              </td>
            </tr>
          );
        })
      )}
    </tbody>
  </table>
);

export default TableOrg;
