import React from "react";
import api from "../utils/api";
import { URL } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";
import { Loader } from "../components/Loader";
import { connect } from "react-redux";
import {
  ListUserMessage,
  MessageContent,
  UserMessage,
} from "../components/MessageContainer";
import { socket } from "../utils/socketIo";

let copyUserMessage = [];

class MessageScreen extends React.Component {
  state = {
    isLoading: true,
    search_text: "",
    user_messages: [],
    conversations: [],
    is_loading_content: false,
    message: "",
    toast_properties: [],
  };

  componentDidMount = async () => {
    await this.getProfile();
    this.getData(true, this.state.user);

    socket.on("chat", () => {
      this.getData(false);
      this.get_conversation(
        false,
        this.state.buyer[0]._id,
        this.state.seller[0]._id
      );
    });
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({ user });
    }
  };

  getData(isLoading) {
    this.setState({ isLoading });

    let data = {
      model: "chat",
      organization: [this.props.organization._id],
      token: this.state.user.token,
      user_id: [this.state.user.user_id],
      tag: "chat_home",
    };
    let config = {
      method: "post",
      url: URL + "/get_chat_info",
      data,
    };

    api(config)
      .then((data) => {
        this.setState({
          isLoading: false,
          user_messages: data.data,
        });
        copyUserMessage = data.data.slice(0);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  get_conversation(is_loading_content, buyer, seller) {
    this.setState({ is_loading_content });

    //body
    let config = {
      method: "post",
      url: URL + "/get_chat_info",
      data: {
        model: "chat",
        buyer: [buyer._id],
        seller: [seller._id],
        token: this.state.user.token,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    api(config)
      .then((data) => {
        this.setState({
          is_loading_content: false,
          conversations: data.data,
          conversation_id: data.data[0].conversation_id,
          participant: data.data[0].participant,
          order_number: data.data[0].order._id,
          seller,
          buyer,
        });
      })
      .catch((err) => {
        this.setState({
          is_loading_content: false,
        });
      });
  }

  onChangeMessage(e) {
    this.setState({
      message: e.target.value,
    });
  }

  onSubmit() {
    const { message } = this.state;

    if (message === "") {
      alert("This is a success toast component");
    } else {
      const date = new Date().getTime();
      let message_data = {
        available: true,
        conversation_id: this.state.conversation_id,
        date,
        message,
        participant: this.state.participant,
        sender: [this.state.user.user_id],
        status: "unread",
        buyer: [this.state.buyer._id],
        seller: [this.state.seller._id],
      };

      this.setState({
        conversations: [...this.state.conversations, message_data],
        message: "",
      });

      //prepare forma data body
      const formData = new FormData();
      formData.append("model", "chat");
      formData.append("buyer", JSON.stringify(message_data.buyer));
      formData.append("seller", JSON.stringify(message_data.seller));
      formData.append("sender", JSON.stringify(message_data.sender));
      formData.append("message", message);
      formData.append("order", JSON.stringify([message_data.order_number]));
      formData.append("status", "unread");
      formData.append("screen", "ChatScreen");
      formData.append("date", message_data.date);
      formData.append(
        "content",
        JSON.stringify({
          sender: message_data.sender,
          buyer: message_data.buyer,
          seller: message_data.seller,
          new_item: message_data,
        })
      );

      formData.append("token", this.state.user.token);

      //sent to the server
      let config = {
        method: "post",
        url: URL + "/add_chat_info",
        data: formData,
        headers: {
          "content-type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };

      api(config)
        .then((data) => {
          let new_data = data.data;
          this.get_conversation(false, this.state.buyer, this.state.seller);

          //handle a notification
          // console.log(new_data);
          let notification_body = {
            receiver: this.state.seller._id,
            profile_image: "",
            receiver_token: this.state.buyer.fb_token,
            conversation_id: data.data.conversation_id,
            order_number: this.state.order_number,
            seller: this.state.seller._id,
            buyer: this.state.buyer._id,
            tab: "ordered",
            seller_name: this.state.seller.username,
            message: data.data.message,
          };

          // // send a notification
          this.saveNotification(notification_body);
        })
        .catch((err) => {
          console.log("error");
          console.log(err.response);
          if (err.response) {
            alert(
              "Request failed, please try again or contact huzamart@polynnetslabs.com "
            );
          }
        });
    }
  }

  saveNotification(body) {
    let config = {
      method: "post",
      url: URL + "/push_notification",
      data: {
        model: "notification",
        token: body.receiver_token,
        pushnotify_token: body.receiver_token,
        user: [body.receiver],
        body: body.message,
        available: true,
        title: body.seller_name,
        profile_image: "N/A",
        target_screen: "ChatScreen",
        date: new Date().getTime(),
        status: "unread",
        receiver_id: [body.receiver],
        channelId: "huzamart",
        support_data: {
          id: body.conversation_id,
          target_screen: "ChatScreen",
          channelId: "huzamart",
          image: body.profile_image,
          order_number: body.order_number,
          buyer: [body.buyer],
          seller: [body.seller],
          conversation_id: body.conversation_id,
          tab: body.tab,
          other: {
            test: "okay",
          },
        },
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    api(config).then(
      ({ data }) => {
        //success
        // console.log({ notification: data.data });
      },
      (err) => {
        alert(
          "Request failed, please try again or contact huzamart@polynnetslabs.com "
        );
      }
    );
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  }

  onChangeSearch(e) {
    let array = [],
      search_text = e.target.value;
    // console.log(copyUserMessage);
    for (let i = 0; i < copyUserMessage.length; i++) {
      if (
        copyUserMessage[i].buyer[0].phone_number &&
        copyUserMessage[i].buyer[0].phone_number
          .toUpperCase()
          .indexOf(search_text.toUpperCase()) !== -1
      ) {
        array.push(copyUserMessage[i]);
      }
    }

    this.setState({
      search_text: e.target.value,
      user_messages: array,
    });
  }

  render() {
    console.log({ copyUserMessage: this.state.user_messages });
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <div className="d-flex">
            <ListUserMessage
              search_text={this.state.search_text}
              onChangeText={this.onChangeSearch.bind(this)}
            >
              {this.state.user_messages.map((item, i) => {
                return (
                  <UserMessage
                    message={item}
                    key={i}
                    handlePress={this.get_conversation.bind(
                      this,
                      true,
                      item.buyer[0],
                      item.seller[0]
                    )}
                  />
                );
              })}
            </ListUserMessage>
            <MessageContent
              is_loading={this.state.is_loading_content}
              conversations={this.state.conversations}
              user={this.state.user}
              message={this.state.message}
              onChangeMessage={this.onChangeMessage.bind(this)}
              onSubmit={this.onSubmit.bind(this)}
              handleKeyDown={this.handleKeyDown.bind(this)}
            />
          </div>
        )}
      </>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization } = state.Organization;
  return {
    organization,
  };
};

export default connect(mapPropsToState)(MessageScreen);
