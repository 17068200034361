import React from "react";
import { connect } from "react-redux";
import { Filter } from "../components/Filter";
import { Header } from "../components/Header";
import { Loader } from "../components/Loader";
import { TableShop } from "../components/Table";
import api from "../utils/api";
import { URL } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";

let copyData = [];

class ShopScreen extends React.Component {
  state = {
    data: [],
    columns: ["Photo", "Name", "Type", "Section", "Action"],
    search_text: "",
    country: "",
  };

  componentDidMount = async () => {
    await this.getProfile();
    this.getData(true, this.state.user);
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({ user });
    }
  };

  getData(isLoading) {
    this.setState({ isLoading, data: [] });

    let data = {
      model: "shop",
      organization: [this.props.organization._id],
      token: this.state.user.token,
      user_id: this.state.user.user_id,
    };
    let config = {
      method: "post",
      url: URL + "/get_shop_info",
      data,
    };

    api(config)
      .then((data) => {
        this.setState({
          isLoading: false,
          data: data.data,
        });
        copyData = data.data.slice(0);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleSearch(e) {
    let new_array = [],
      text = e.target.value.toUpperCase();

    for (let i = 0; i < copyData.length; i++) {
      if (copyData[i].shop_name.toUpperCase().indexOf(text) !== -1) {
        new_array.push(copyData[i]);
      }
    }

    this.setState({
      data: new_array,
      search_text: e.target.value,
    });
  }

  onDelete(item, i) {
    if (window.confirm("Are you sure want to delete this data")) {
      this.state.data.splice(i, 1);

      this.setState({
        data: this.state.data,
      });

      let formData = new FormData();
      formData.append("model", "shop");
      formData.append("available", false);
      formData.append("id", item._id);
      formData.append("token", this.state.user.token);
      let config = {
        method: "POST",
        url: URL + "/update_shop_info",
        data: formData,
      };

      api(config)
        .then((data) => {
          this.getData(false);
        })
        .catch((err) => console.log(err));
    }
  }

  onEdit(item) {
    window.location.href = "/app/create_shop/" + item._id;
  }

  onChangeCountry(e) {
    let country = e.target.value.toUpperCase(),
      new_array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (copyData[i].country.toUpperCase().indexOf(country) !== -1) {
        new_array.push(copyData[i]);
      }
    }

    this.setState({
      data: new_array,
      country: e.target.value,
    });
  }

  render() {
    return (
      <>
        <Header
          title="Shop & Restaurant"
          showAddBtn={true}
          route="/app/create_shop"
        />
        <Filter
          search_text={this.state.search_text}
          onChange={this.handleSearch.bind(this)}
          country={this.state}
          onChangeCountry={this.onChangeCountry.bind(this)}
          showCountry
        />
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <TableShop
            data={this.state.data}
            columns={this.state.columns}
            onDelete={this.onDelete.bind(this)}
            onEdit={this.onEdit.bind(this)}
          />
        )}
      </>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization } = state.Organization;
  return {
    organization,
  };
};

export default connect(mapPropsToState)(ShopScreen);
