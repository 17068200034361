import React from "react";
import "./styles.css";
import { Product } from "../Product";
import { Link } from "react-router-dom";
import { TableActionButton } from ".";

const TableOrg = ({ className, data, columns, onDelete, openModal }) => (
  <table className={`table table-hover ${className}`}>
    <thead>
      <tr>
        {columns.map((item, i) => {
          return <th key={i}>{item}</th>;
        })}
      </tr>
    </thead>
    <tbody>
      {data.length === 0 ? (
        <tr>
          <td align="center" colSpan={10}>
            No data found
          </td>
        </tr>
      ) : (
        data.map((item, i) => {
          return (
            <tr key={i}>
              <td>
                <Link to={`/app/products/${item._id}`}>
                  <Product title={item.title} file_url={item.file_url} />
                </Link>
              </td>
              <td>
                <b>{item.currency + " " + item.price}</b>
              </td>
              <td>{item.flag}</td>
              <td>{item.product_type}</td>
              <td>{item.category}</td>
            </tr>
          );
        })
      )}
    </tbody>
  </table>
);

export default TableOrg;
