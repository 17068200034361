import React from "react";
import { Button } from "../Form/Button";
import { Input } from "../Form/Input";
import { Select } from "../Form/Select";
import "./styles.css";
import { Alert } from "../Alert";
import { HUZAMART_IN_COUNTRIES } from "../../utils/CONSTANTS";

const NewShop = ({
  onChangeInput,
  error,
  onChangeImages,
  onSubmit,
  category,
  shop_name,
  shop_type,
  isSubmitting,
  onChangeSection,
  country,
  onChangeCountry,
  sections,
}) => (
  <div className="row">
    <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          {error.success_message && (
            <Alert className="alert-success">{error.success_message}</Alert>
          )}
          {error.error_message && (
            <Alert className="alert-danger"> {error.error_message}</Alert>
          )}
          <form>
            <div className="row">
              <div className="col-md-6">
                <Input
                  label={true}
                  label_title="Shop name:"
                  value={shop_name}
                  onChange={(e) => onChangeInput(e, "shop_name")}
                />
              </div>
              <div className="col-md-6">
                <Select
                  data={["Coffee Shop", "Restaurant"]}
                  label={true}
                  label_title="Shop type:"
                  value={shop_type}
                  onChange={(e) => onChangeInput(e, "shop_type")}
                />
              </div>
              <div className="col-md-12" style={{ marginBottom: 15 }}>
                <select
                  label={true}
                  label_title="Country:"
                  className="form-control"
                  value={shop_type}
                  onChange={(e) => onChangeInput(e, "shop_type")}
                >
                  <option disabled selected>
                    Select country
                  </option>
                  {HUZAMART_IN_COUNTRIES.map((item, i) => {
                    return <option key={i}>{item.name}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-12">
                <Select
                  data={sections}
                  label={true}
                  label_title="Section:"
                  isCreatable
                  value={sections}
                  onChange={onChangeSection}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          <form>
            <Input
              type="file"
              label={true}
              label_title="Upload Images"
              onChange={onChangeImages}
            />
          </form>
        </div>
      </div>
    </div>
    <div className="col-md-12 mt-1">
      <div className="card">
        <div className="card-body">
          <Button
            title="Submit"
            className="btn-primary"
            isSubmitting={isSubmitting}
            handlePress={onSubmit}
          />
        </div>
      </div>
    </div>
  </div>
);

export default NewShop;
