import React from "react";
import "./styles.css";

const Button = ({
  className,
  title,
  handlePress,
  isSubmitting,
  isLink,
  route,
}) => (
  <>
    {isLink ? (
      <a
        style={{ color: "white" }}
        href={route}
        className={`btn ${className}`}
        disabled={isSubmitting}
      >
        {isSubmitting ? "Please wait..." : title}
      </a>
    ) : (
      <button
        className={`btn ${className}`}
        disabled={isSubmitting}
        onClick={handlePress}
      >
        {isSubmitting ? "Please wait..." : title}
      </button>
    )}
  </>
);

export default Button;
