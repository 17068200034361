import React from "react";
import "./styles.css";
import Logo from "../../../assets/logo.png"

const Header = ({title}) => (
	<center className="form_header text-center">
		<img src={Logo}/>
		<h1 className="h1">{title}</h1>
	</center>
);

export default Header;
