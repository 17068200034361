import React from "react";
import "./styles.css";
const DefaultOrganization = ({ organization, organizations, onChangeOrg}) => (
	<div className="default_org">
		<div className="select_container col-md-3">
			{
				organization._id && <div className="org_placeholder">
					{organization.file_url.length !== 0 ? <img src={organization.file_url[0]}/> : organization.name.charAt(0)}
				</div>
			}
			<select className="form-control " value={organization.name} onChange={onChangeOrg}>
				{organizations.map((item, i) => {
					return <option key={i} data-item={i}>
					{item.name}
				</option>
				})}
			</select>
		</div>
	</div>
);
export default DefaultOrganization;
