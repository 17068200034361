import React from "react";
import {
  countries,
  categories,
  HUZAMART_IN_COUNTRIES,
  HUZAMART_IN_DEALS,
} from "../../utils/CONSTANTS";
import { Input } from "../Form/Input";
import "./styles.css";
const Filter = ({
  search_text,
  onChange,
  showCountry,
  showFlag,
  onChangeCountry,
  onChangeFlag,
  onChangeCategory,
  showCategory,
}) => (
  <div className="filter">
    <div className="row">
      <div className="col-md-3">
        <Input
          placeholder="Search..."
          className="form-control-sm"
          value={search_text}
          onChange={onChange}
        />
      </div>
      {showCountry && (
        <div className="col-md-3">
          <select
            className="form-control form-control-sm"
            onChange={onChangeCountry}
          >
            <option disabled selected>
              Filter by Country
            </option>
            {HUZAMART_IN_COUNTRIES.map((item, i) => {
              return <option key={i}>{item.name}</option>;
            })}
          </select>
        </div>
      )}
      {showFlag && (
        <div className="col-md-3">
          <select
            className="form-control form-control-sm"
            onChange={onChangeFlag}
          >
            <option disabled selected>
              Filter by Deal
            </option>
            {HUZAMART_IN_DEALS.map((item, i) => {
              return <option key={i}>{item.type}</option>;
            })}
          </select>
        </div>
      )}
      {showCategory && (
        <div className="col-md-3">
          <select
            className="form-control form-control-sm"
            onChange={onChangeCategory}
          >
            <option disabled selected>
              Filter by Category
            </option>
            {categories.map((item, i) => {
              return <option key={i}>{item.title}</option>;
            })}
          </select>
        </div>
      )}
    </div>
  </div>
);
export default Filter;
