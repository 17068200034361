import React from "react";
import { Button } from "../Form/Button";
import "./styles.css";
const Header = ({
  title,
  showAddBtn,
  route,
  showEdit,
  isLink,
  handlePress,
  button_text,
}) => (
  <div className="container">
    <div className="app_c_header">
      <h1 className="h1">{title}</h1>
      {showAddBtn && (
        <Button
          route={route}
          isLink={isLink}
          title={button_text ? button_text : "Create new"}
          className="btn-primary"
          handlePress={handlePress}
        />
      )}
      {showEdit && (
        <Button
          route={route}
          isLink={true}
          title="Edit Product"
          className="btn-primary"
        />
      )}
    </div>
  </div>
);
export default Header;
