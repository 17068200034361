import React from "react"
import "./styles.css"
import { Link } from "react-router-dom"
import moment from "moment"

const TableOrg = ({className, data, columns}) => <table className={`table table-hover ${className}`}>
    <thead>
        <tr>
            {
                columns.map((item, i) => {
                    return  <th key={i}>{item}</th>
                })
            }
        </tr>
    </thead>
    <tbody>
        {
            data.length === 0 ? <tr>
                <td align="center" colSpan={10}>No data found</td>
            </tr>
            :
            data.map((item, i) => {
                return <tr key={i}>
                    <td>
                        <Link to={`/app/orders/${item._id}`}>
                            <b>{item._id}</b>
                        </Link>
                    </td>
                    <td>
                        <b>{item.currency+" "+item.cost}</b>
                    </td>
                    <td>{item.address}</td>
                    <td><a href=""><b>{item.consumer[0].email ? item.consumer[0].email : item.consumer[0].phone_number}</b></a></td>
                    <td>{moment(item.date).format("lll")}</td>
                </tr>
            })
        }
    </tbody>
</table>

export default TableOrg