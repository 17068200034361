import React from "react";
import { Header } from "../components/Header";
import { OrderDetail } from "../components/OrderDetail";
import api from "../utils/api";
import { URL } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";
import { Loader } from "../components/Loader";

class OrderDetailScreen extends React.Component {
  state = {
    data: [],
    selected_img: 0,
  };

  componentDidMount = async () => {
    await this.getProfile();
    if (this.props.match.params) {
      const { order_id } = this.props.match.params;
      this.getData(true, order_id);
    } else {
      alert("Missing product ID");
    }
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({ user });
    }
  };

  getData(isLoading, _id) {
    this.setState({ isLoading, data: [] });

    let data = {
      model: "order",
      id: [_id],
      token: this.state.user.token,
    };
    let config = {
      method: "post",
      url: URL + "/get_order_info",
      data,
    };

    api(config)
      .then((data) => {
        // console.log(data.data);
        this.setState({
          isLoading: false,
          data: data.data,
        });
        // console.log(data.data);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <>
        {this.state.data.length !== 0 && (
          <Header title={this.state.data[0]._id} showAddBtn={false} />
        )}
        {this.state.isLoading ? (
          <Loader />
        ) : (
          this.state.data.length !== 0 && <OrderDetail data={this.state.data} sub_total={0} total={0} />
        )}
      </>
    );
  }
}

export default OrderDetailScreen;
