import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { TableActionButton } from "./";
import moment from "moment";


const TableOrg = ({ className, data, handlePress, openModal, onDelete }) => (
  <table className={`table table-hover ${className}`}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Created at</th>
        <th>Activated</th>
        <th>Category</th>
        <th>Product</th>
        <th>Order</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {data.length === 0 ? (
        <tr>
          <td align="center" colSpan={10}>
            No data found
          </td>
        </tr>
      ) : (
        data.map((item, i) => {
          return (
            <tr key={i}>
              <td onClick={() => handlePress(item)}>
                <b>{item.name}</b>
              </td>
              <td>{moment(item.date).format("lll")}</td>
              <td>{item.eshop_activated? "True": "False"}</td>
              <td onClick={() => handlePress(item)}>
                <Link to="/app/categories">
                  <b>View</b>
                </Link>
              </td>
              <td onClick={() => handlePress(item)}>
                <Link to="/app/products">
                  <b>View</b>
                </Link>
              </td>
              <td onClick={() => handlePress(item)}>
                <Link to="/app/orders">
                  <b>View</b>
                </Link>
              </td>
              <td>
                <TableActionButton
                  show_delete
                  show_edit
                  onDelete={() => onDelete(item, i)}
                  onEdit={() => openModal(item)}
                />
              </td>
            </tr>
          );
        })
      )}
    </tbody>
  </table>
);

export default TableOrg;
