import React from "react";
import { Header } from "../components/Form/Header";
import { Register } from "../components/Register";
import api from "../utils/api";
import { URL } from "../utils/CONSTANTS";
import { setStorage } from "../utils/sessionStorage";

class RegisterScreen extends React.Component {
  state = {
    name:"",
    email: "",
    password: "",
    password2:"",
    isSubmitting: false,
    error: {},
    redirecting: false,
  };

  onChangeInput(e, key) {
    this.setState({
      [key]: e.target.value,
      error: {},
    });
  }

  validateForm() {
    let isValid = true;

    if (this.state.email === "") {
      isValid = false;

      let email = "Email address is required",
      error = {error_message:email};
      this.setState({ error: error });

    }

    if (this.state.password === "") {
      isValid = false;

      let password = "Password is required",
      error = {error_message:password};
      this.setState({ error: error });

    } else if (this.state.password.length < 3) {
      isValid = false;

      let password = "Password is too short",
      error = {error_message:password};
      this.setState({ error: error });

    }
    
    if (this.state.password !== this.state.password2){
      isValid = false;

      let password = "Password should match",
      error = {error_message:password};
      this.setState({ error: error });

    }

    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({ isSubmitting: true });
      const { name, email, password } = this.state,
      cpassword = password;
      let data = {
        username:name,
        email,
        password,
        cpassword,
      };
      let config = {
        method: "post",
        url: URL + "/register_web",
        data,
      };

      api(config)
        .then((data) => {
          let error = {};
          error.success_message = data.data.message;

          //store user
          setStorage(data.data);

          this.setState({
            isSubmitting: false,
            error: error
          });
        })
        .catch((err) => {
          let error = {};
          if (err.response) {
            error.error_message = err.response.data.message;
          }
          this.setState({
            isSubmitting: false,
            error: error,
          });
        });
    }
  }

  render() {
    return (
      <div className="container">
        <div className="col-md-4 mx-auto">
          <Header title="Join Huzamart" />
          
          <Register 
            onChangeInput={this.onChangeInput.bind(this)}
            name={this.state.name}
            email={this.state.email}
            password={this.state.password}
            password2={this.state.password2}
            isSubmitting={this.state.isSubmitting}
            error={this.state.error}
            onSubmit={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default RegisterScreen;
