import React from "react";
import { Button } from "../Form/Button";
import "./styles.css";
import { Modal } from "react-bootstrap";

const ModalComponent = ({
  handleClose,
  show,
  title,
  children,
  size,
  centered,
}) => (
  <Modal show={show} onHide={handleClose} size={size} centered={centered}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      {/* <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" onClick={handleClose}>
        Save Changes
      </Button> */}
    </Modal.Footer>
  </Modal>
);

export default ModalComponent;
