import { ON_SET, ON_FETCH } from "../action/Organization";

//initiate state
const initialState = {
  organization: sessionStorage.getItem("organization")
    ? JSON.parse(sessionStorage.getItem("organization"))
    : {},
  organizations: sessionStorage.getItem("organizations")
    ? JSON.parse(sessionStorage.getItem("organizations"))
    : [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_FETCH: {
      sessionStorage.setItem("organizations", JSON.stringify(action.data));
      return {
        ...state,
        organizations: [...action.data],
      };
    }
    case ON_SET: {
      sessionStorage.setItem(
        "organization",
        JSON.stringify(action.organization)
      );
      return {
        ...state,
        organization: { ...action.organization },
      };
    }
    default:
      return state;
  }
};
export default reducer;
