export const ON_SET = "ON_SET";
export const ON_FETCH = "ON_FETCH";

export const onSetOrganization = (organization) => {
  return {
    type: ON_SET,
    organization
  };
};

export const onFetchOrganization = (data) => {
  return {
    type: ON_FETCH,
    data
  };
}
