import { APP_NAME } from "./CONSTANTS";

export const getStorage = () => {
  let data = JSON.parse(sessionStorage.getItem(APP_NAME));

  return data;
};

export const setStorage = (data) => {
  return sessionStorage.setItem(APP_NAME, JSON.stringify(data));
};
