import React from "react";
import { SIDE_MENUS } from "../../utils/CONSTANTS";
import "./styles.css";
import { List } from "../List";

const Sidebar = ({ active, isToggled }) => (
  <div className={isToggled ? "sidebar sm" : "sidebar"}>
    <div className="list-group">
      {SIDE_MENUS.map((item, i) => {
        return (
          <List
            route={item.route}
            active={active.includes(item.name.toLowerCase())}
            key={i}
            icon={item.icon}
            title={item.name}
          />
        );
      })}
    </div>
  </div>
);
export default Sidebar;
