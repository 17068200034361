export const APP_NAME = "HUZAMART_DASHBOARD";
export const URL = "https://apihuzamart.huzalabs.com/api/huzamart";
export const SOCKET_END_POINT = "https://apihuzamart.huzalabs.com";
export const SIDE_MENUS = [
  {
    name: "Organization",
    icon: "fa fa-home",
    route: "/app/organization",
  },
  {
    name: "Shops",
    icon: "fa fa-store",
    route: "/app/shops",
  },
  {
    name: "Products",
    icon: "fa fa-layer-group",
    route: "/app/products",
  },
  {
    name: "Orders",
    icon: "fa fa-box",
    route: "/app/orders",
  },
  {
    name: "Messages",
    icon: "fa fa-inbox",
    route: "/app/messages#no_o",
  },
  {
    name: "Categories",
    icon: "fa fa-list-alt",
    route: "/app/categories",
  },
  {
    name: "Notifications",
    icon: "fa fa-bell",
    route: "/app/notifications",
  },
  {
    name: "Billing & Transactions",
    icon: "fa fa-credit-card",
    route: "/app/billing_transactions",
  },
  {
    name: "Settings",
    icon: "fa fa-cog",
    route: "/app/settings/account",
  },
];

export const USER_IMAGE_PLACEHOLDER =
  "https://st3.depositphotos.com/4111759/13425/v/380/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg";
export const GOOGLE_API_KEY = "AIzaSyBQ9M0eDlriz5dFp5eMB5yMN3BIbfV_AI4";
export const ITEM_IMAGE_PLACEHOLDER =
  "https://res.cloudinary.com/dwqhmch33/image/upload/v1598461530/placeholder.png";
export const categories = [
  /*{
      title: 'All Categories',
      data: [
        {
          name: 'All Categories',
          image:
            'https://res.cloudinary.com/polynnet/image/upload/v1583918829/all_lff0ao.png',
        },
      ],
      image:
        'https://res.cloudinary.com/polynnet/image/upload/v1583918829/all_lff0ao.png',
      background: '#2FC145',
    }*/
  {
    title: "Food & Groceries",
    data: [
      {
        name: "Fresh vegetables",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743458/veggies_fygfoi.png",
      },
      {
        name: "Fresh fruits",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743457/frutas_7_gddicl.png",
      },
      {
        name: "Meat & Seafood",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743457/enzi-center-cut-chunks_hiol2o.png",
      },
      {
        name: "Dairy & Drinks",
        image: "https://www.neologicengineers.com/images/dairy-popup.png",
      },
      {
        name: "Grains",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSeRl3IyInoWt5QCUlanbshju1hf9i5DXMMdFpHS112gCTXptbe&usqp=CAU",
      },
      {
        name: "Other",
        image:
          "https://static.wixstatic.com/media/6c23c7_833feda861564935aaac8019b68edc93~mv2.png",
      },
    ],
    image:
      "https://res.cloudinary.com/polynnet/image/upload/v1589645481/groceries_tdfquv.png",
    background: "#efdf00",
    default: true,
  },
  {
    title: "Consumer Electronics",
    data: [
      {
        name: "Computer Hardware & Software",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743457/2019122611041231_hu7i0g.png",
      },
      {
        name: "Home Audio, Video & Accessories",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743458/Home-Theater-TV-and-Video-Accessories-final470_ex1ev2.png",
      },
      {
        name: "Earphone & Headphone",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743458/img_vmg2bi.png",
      },
      {
        name: "Mobile Phone & Accessories",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743457/334c7e3c9153557d5e2c73d35b2b3e53_sgbm4r.png",
      },
      {
        name: "Camera, Photo & Accessories",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743455/768d97127a0678fe4b00875b7b953496_esdrdd.png",
      },
      {
        name: "Other",
        image: "https://i.ebayimg.com/images/g/1iQAAOSwi-dbqyXP/s-l400.webp",
      },
    ],
    image:
      "https://res.cloudinary.com/polynnet/image/upload/v1583916911/eletronics_zlh3ob.png",
    background: "#2FC145", //background: '#2FC145'
  },
  {
    title: "Flowers & Gifts",
    data: [
      {
        name: "Flowers & Gifts",
        image:
          "https://images.esellerpro.com/3274/I/216/36/POP143%20-%20flowers.png",
      },
      {
        name: "Flowers & Balloon",
        image:
          "https://p7.hiclipart.com/preview/488/53/311/balloon-gift-download-gift.jpg",
      },
      {
        name: "Other",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1589533557/19164_flkvof.png",
      },
    ],
    image:
      "https://res.cloudinary.com/polynnet/image/upload/v1589534055/petals_ivcxjj_cft0go.png",
    background: "#f47721",
  },
  {
    title: "Men's Clothing & Shoes",
    data: [
      {
        name: "Shoes",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743458/Kids-Basketball-Shoes-Sneakers-Kids-13-Retro-Basketball-Sport-1-Shoes-Children-11-Retro-Shoes-Athletic_ajnz4o.png",
      },
      {
        name: "Underwear",
        image:
          "https://huza.nyc3.digitaloceanspaces.com/huzamart/categories/men/men-underwear.png",
      },
      {
        name: "Belts and Wallets",
        image:
          "https://huza.nyc3.digitaloceanspaces.com/huzamart/categories/men/belts%20waallet.png",
      },
      {
        name: "Ties & wearables",
        image:
          "https://huza.nyc3.digitaloceanspaces.com/huzamart/categories/men/shadeswatchtie.jpeg",
      },
      {
        name: "Casual clothes",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583744953/08b02f57eba5ae933610448644235b9b_meej6c.png",
      },
      {
        name: "Other",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1589149934/SSBGPBUT_Organic_Butterfly_Print_2-Pack_SS_Babygrow_jpiajf.png",
      },
    ],
    image:
      "https://res.cloudinary.com/polynnet/image/upload/v1583916148/menclothes_b84dto.png",
    background: "#037ef3",
  },
  {
    title: "Women's Clothing & Shoes",
    data: [
      {
        name: "Shoes",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583918620/Women-Casual-Shoes-Side-Zip-Loafers-Platform-Sneakers-Oxford-Shoes-Woman-Wedges-Sneaker-Slip-on-espadrilles.jpg_640x640_kfksfd.png",
      },
      {
        name: "Glasses and Bracelets",
        image:
          "https://huza.nyc3.digitaloceanspaces.com/huzamart/categories/men/shadeswatchtie.jpeg",
      },
      {
        name: "Bags and Purses",
        image:
          "https://huza.nyc3.digitaloceanspaces.com/huzamart/categories/women/women/bugspurses.png",
      },
      {
        name: "Official & Ceremonial clothes",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583745042/black-suit-png-2_jkxmbt.png",
      },
      {
        name: "Casual clothes",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583918695/2019-Autumn-New-Women-Chiffon-Blouse-Office-Lady-V-neck-Button-Loose-Clothing-Casual-Solid-Female_owdmez.webp",
      },
      {
        name: "Other",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583745091/unnamed_tjfqtb.png",
      },
    ],
    image:
      "https://res.cloudinary.com/polynnet/image/upload/v1583916533/womanclothes_iifyoh.png",
    background: "#ff4f81",
  },
  {
    title: "Shop & Restaurant",
    data: [
      {
        name: "Coffee Shop",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1589149985/shop-icon_zmdg8w.png",
      },
      {
        name: "Restaurant",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1589150017/Select-Restaurant_xcc0ch.png",
      },
    ],
    image:
      "https://res.cloudinary.com/polynnet/image/upload/v1589642854/restaurant_tjguuz.png",
    background: "#ff4816",
  },
  {
    title: "Services",
    data: [
      {
        name: "Cooking gas",
        image:
          "https://huza.nyc3.digitaloceanspaces.com/huzamart/categories/services/cgas.png",
      },
      {
        name: "Electrical repair",
        image:
          "https://huza.nyc3.digitaloceanspaces.com/huzamart/categories/services/homeelectricity.png",
      },
      {
        name: "Plumbing",
        image:
          "https://huza.nyc3.digitaloceanspaces.com/huzamart/categories/services/plumbinggreen.png",
      },
      {
        name: "Home shifting",
        image:
          "https://huza.nyc3.digitaloceanspaces.com/huzamart/categories/services/houseshifting.jpg",
      },
      {
        name: "Car repair",
        image:
          "https://huza.nyc3.digitaloceanspaces.com/huzamart/categories/services/carrepairnobg.png",
      },
      {
        name: "Other",
        image:
          "https://huza.nyc3.digitaloceanspaces.com/huzamart/categories/services/servicesother.png",
      },
    ],
    image:
      "https://huza.nyc3.digitaloceanspaces.com/huzamart/categories/services/services.png",
    background: "#5e5f60",
  },
  {
    title: "Toys & Kid Clothes",
    data: [
      {
        name: "Clothes",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1589792443/fashion_1_yhfbv3.png",
      },
      {
        name: "Toys",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1589792354/children_i0y13e.png",
      },
    ],
    image:
      "https://res.cloudinary.com/polynnet/image/upload/v1589792354/children_i0y13e.png",
    background: "#ff4816",
  },
  {
    title: "Beauty & Personal Care",
    data: [
      {
        name: "Hair Extensions & Wigs",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743456/02ee05c97d8a0022de08c5561f0f2c9f_fxcoxf.png",
      },
      {
        name: "Makeup",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743458/hd-makeup-1_lhjdk5.png",
      },
      {
        name: "Makeup Tools",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743457/5bf829e5ac6506610759c31200fae770_gz7rux.png",
      },
      {
        name: "Skin Care",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743458/polish_nc_1_uhqwcw.png",
      },
      {
        name: "Nail Supplies",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583743457/b091ff8c55c9242a697e386c2d850cad_ibhlt5.png",
      },
      {
        name: "Other",
        image:
          "https://pmcwwd.files.wordpress.com/2017/01/cerave-product-range.png?crop=0px%2C10px%2C600px%2C400px&resize=640%2C415",
      },
    ],
    image:
      "https://res.cloudinary.com/polynnet/image/upload/v1583918124/beauty_dn7zwr.png",
    background: "#ff4816",
  },
  {
    title: "Other Category",
    data: [
      {
        name: "Other",
        image:
          "https://res.cloudinary.com/polynnet/image/upload/v1583916911/eletronics_zlh3ob.png",
      },
    ],
    image:
      "https://res.cloudinary.com/polynnet/image/upload/v1587296747/clipboard_eskvo2.png",
    background: "#ddd",
  },
];

export const countries = [
  {
    dial_code: "+355",
    flag: "https://cdn.countryflags.com/thumbs/albania/flag-square-250.png",
    code: "AL",
    name: "Albania",
  },
  {
    dial_code: "+376",
    flag: "https://cdn.countryflags.com/thumbs/andorra/flag-square-250.png",
    code: "AD",
    name: "AndorrA",
  },
  {
    dial_code: "+1 268",
    flag: "https://cdn.countryflags.com/thumbs/antigua-and-barbuda/flag-square-250.png",
    code: "AG",
    name: "Antigua and Barbuda",
  },
  {
    dial_code: "+374",
    flag: "https://cdn.countryflags.com/thumbs/armenia/flag-square-250.png",
    code: "AM",
    name: "Armenia",
  },
  {
    dial_code: "+43",
    flag: "https://cdn.countryflags.com/thumbs/austria/flag-square-250.png",
    code: "AT",
    name: "Austria",
  },
  {
    dial_code: "+994",
    flag: "https://cdn.countryflags.com/thumbs/azerbaijan/flag-square-250.png",
    code: "AZ",
    name: "Azerbaijan",
  },
  {
    dial_code: "+1 242",
    flag: "https://cdn.countryflags.com/thumbs/bahamas/flag-square-250.png",
    code: "BS",
    name: "Bahamas",
  },
  {
    dial_code: "+973",
    flag: "https://cdn.countryflags.com/thumbs/bahrain/flag-square-250.png",
    code: "BH",
    name: "Bahrain",
  },
  {
    dial_code: "+1 246",
    flag: "https://cdn.countryflags.com/thumbs/barbados/flag-square-250.png",
    code: "BB",
    name: "Barbados",
  },
  {
    dial_code: "+375",
    flag: "https://cdn.countryflags.com/thumbs/belarus/flag-square-250.png",
    code: "BY",
    name: "Belarus",
  },
  {
    dial_code: "+32",
    flag: "https://cdn.countryflags.com/thumbs/belgium/flag-square-250.png",
    code: "BE",
    name: "Belgium",
  },
  {
    dial_code: "+501",
    flag: "https://cdn.countryflags.com/thumbs/belize/flag-square-250.png",
    code: "BZ",
    name: "Belize",
  },
  {
    dial_code: "+229",
    flag: "https://cdn.countryflags.com/thumbs/benin/flag-square-250.png",
    code: "BJ",
    name: "Benin",
  },
  {
    dial_code: "+975",
    flag: "https://cdn.countryflags.com/thumbs/bhutan/flag-square-250.png",
    code: "BT",
    name: "Bhutan",
  },
  {
    dial_code: "+591",
    flag: "https://cdn.countryflags.com/thumbs/bolivia/flag-square-250.png",
    code: "BO",
    name: "Bolivia",
  },
  {
    dial_code: "+387",
    flag: "https://cdn.countryflags.com/thumbs/bosnia-and-herzegovina/flag-square-250.png",
    code: "BA",
    name: "Bosnia and Herzegovina",
  },
  {
    dial_code: "+267",
    flag: "https://cdn.countryflags.com/thumbs/botswana/flag-square-250.png",
    code: "BW",
    name: "Botswana",
  },
  {
    dial_code: "+359",
    flag: "https://cdn.countryflags.com/thumbs/bulgaria/flag-square-250.png",
    code: "BG",
    name: "Bulgaria",
  },
  {
    dial_code: "+226",
    flag: "https://cdn.countryflags.com/thumbs/burkina-faso/flag-square-250.png",
    code: "BF",
    name: "Burkina Faso",
  },
  {
    dial_code: "+257",
    flag: "https://cdn.countryflags.com/thumbs/burundi/flag-square-250.png",
    code: "BI",
    name: "Burundi",
  },
  {
    dial_code: "+855",
    flag: "https://cdn.countryflags.com/thumbs/cambodia/flag-square-250.png",
    code: "KH",
    name: "Cambodia",
  },
  {
    dial_code: "+237",
    flag: "https://cdn.countryflags.com/thumbs/cameroon/flag-square-250.png",
    code: "CM",
    name: "Cameroon",
  },
  {
    dial_code: "+1",
    flag: "https://cdn.countryflags.com/thumbs/canada/flag-square-250.png",
    code: "CA",
    name: "Canada",
  },
  {
    dial_code: "+238",
    flag: "https://cdn.countryflags.com/thumbs/cape-verde/flag-square-250.png",
    code: "CV",
    name: "Cape Verde",
  },
  {
    dial_code: "+236",
    flag: "https://cdn.countryflags.com/thumbs/central-african-republic/flag-square-250.png",
    code: "CF",
    name: "Central African Republic",
  },
  {
    dial_code: "+235",
    flag: "https://cdn.countryflags.com/thumbs/chad/flag-square-250.png",
    code: "TD",
    name: "Chad",
  },
  {
    dial_code: "+56",
    flag: "https://cdn.countryflags.com/thumbs/chile/flag-square-250.png",
    code: "CL",
    name: "Chile",
  },
  {
    dial_code: "+86",
    flag: "https://cdn.countryflags.com/thumbs/china/flag-square-250.png",
    code: "CN",
    name: "China",
  },
  {
    dial_code: "+269",
    flag: "https://cdn.countryflags.com/thumbs/comoros/flag-square-250.png",
    code: "KM",
    name: "Comoros",
  },
  {
    dial_code: "+242",
    flag: "https://cdn.countryflags.com/thumbs/congo-republic-of-the/flag-square-250.png",
    code: "CG",
    name: "Congo",
  },
  {
    dial_code: "+506",
    flag: "https://cdn.countryflags.com/thumbs/costa-rica/flag-square-250.png",
    code: "CR",
    name: "Costa Rica",
  },
  {
    dial_code: "+385",
    flag: "https://cdn.countryflags.com/thumbs/croatia/flag-square-250.png",
    code: "HR",
    name: "Croatia",
  },
  {
    dial_code: "+53",
    flag: "https://cdn.countryflags.com/thumbs/cuba/flag-square-250.png",
    code: "CU",
    name: "Cuba",
  },
  {
    dial_code: "+357",
    flag: "https://cdn.countryflags.com/thumbs/cyprus/flag-square-250.png",
    code: "CY",
    name: "Cyprus",
  },
  {
    dial_code: "+420",
    flag: "https://cdn.countryflags.com/thumbs/czech-republic/flag-square-250.png",
    code: "CZ",
    name: "Czech Republic",
  },
  {
    dial_code: "+45",
    flag: "https://cdn.countryflags.com/thumbs/denmark/flag-square-250.png",
    code: "DK",
    name: "Denmark",
  },
  {
    dial_code: "+253",
    flag: "https://cdn.countryflags.com/thumbs/djibouti/flag-square-250.png",
    code: "DJ",
    name: "Djibouti",
  },
  {
    dial_code: "+1 767",
    flag: "https://cdn.countryflags.com/thumbs/dominican-republic/flag-square-250.png",
    code: "DM",
    name: "Dominica",
  },
  {
    dial_code: "+1 809",
    flag: "https://cdn.countryflags.com/thumbs/dominican-republic/flag-square-250.png",
    code: "DO",
    name: "Dominican Republic",
  },
  {
    dial_code: "+593",
    flag: "https://cdn.countryflags.com/thumbs/ecuador/flag-square-250.png",
    code: "EC",
    name: "Ecuador",
  },
  {
    dial_code: "+503",
    flag: "https://cdn.countryflags.com/thumbs/el-salvador/flag-square-250.png",
    code: "SV",
    name: "El Salvador",
  },
  {
    dial_code: "+240",
    flag: "https://cdn.countryflags.com/thumbs/equatorial-guinea/flag-square-250.png",
    code: "GQ",
    name: "Equatorial Guinea",
  },
  {
    dial_code: "+291",
    flag: "https://cdn.countryflags.com/thumbs/eritrea/flag-square-250.png",
    code: "ER",
    name: "Eritrea",
  },
  {
    dial_code: "+372",
    flag: "https://cdn.countryflags.com/thumbs/estonia/flag-square-250.png",
    code: "EE",
    name: "Estonia",
  },
  {
    dial_code: "+251",
    flag: "https://cdn.countryflags.com/thumbs/ethiopia/flag-square-250.png",
    code: "ET",
    name: "Ethiopia",
  },
  {
    dial_code: "+679",
    flag: "https://cdn.countryflags.com/thumbs/fiji/flag-square-250.png",
    code: "FJ",
    name: "Fiji",
  },
  {
    dial_code: "+358",
    flag: "https://cdn.countryflags.com/thumbs/finland/flag-square-250.png",
    code: "FI",
    name: "Finland",
  },
  {
    dial_code: "+33",
    flag: "https://cdn.countryflags.com/thumbs/france/flag-square-250.png",
    code: "FR",
    name: "France",
  },
  {
    dial_code: "+44",
    flag: "https://cdn.countryflags.com/thumbs/gabon/flag-square-250.png",
    code: "GA",
    name: "Gabon",
  },
  {
    dial_code: "+220",
    flag: "https://cdn.countryflags.com/thumbs/gambia/flag-square-250.png",
    code: "GM",
    name: "Gambia",
  },
  {
    dial_code: "+594",
    flag: "https://cdn.countryflags.com/thumbs/georgia/flag-square-250.png",
    code: "GE",
    name: "Georgia",
  },
  {
    dial_code: "+233",
    flag: "https://cdn.countryflags.com/thumbs/ghana/flag-square-250.png",
    code: "GH",
    name: "Ghana",
  },
  {
    dial_code: "+30",
    flag: "https://cdn.countryflags.com/thumbs/greece/flag-square-250.png",
    code: "GR",
    name: "Greece",
  },
  {
    dial_code: "+995",
    flag: "https://cdn.countryflags.com/thumbs/grenada/flag-square-250.png",
    code: "GD",
    name: "Grenada",
  },
  {
    dial_code: "+502",
    flag: "https://cdn.countryflags.com/thumbs/guatemala/flag-square-250.png",
    code: "GT",
    name: "Guatemala",
  },
  {
    dial_code: "+224",
    flag: "https://cdn.countryflags.com/thumbs/papua-new-guinea/flag-square-250.png",
    code: "GN",
    name: "Guinea",
  },
  {
    dial_code: "+592",
    flag: "https://cdn.countryflags.com/thumbs/guyana/flag-square-250.png",
    code: "GY",
    name: "Guyana",
  },
  {
    dial_code: "+509",
    flag: "https://cdn.countryflags.com/thumbs/haiti/flag-square-250.png",
    code: "HT",
    name: "Haiti",
  },
  {
    dial_code: "+504",
    flag: "https://cdn.countryflags.com/thumbs/honduras/flag-square-250.png",
    code: "HN",
    name: "Honduras",
  },
  {
    dial_code: "+36",
    flag: "https://cdn.countryflags.com/thumbs/hungary/flag-square-250.png",
    code: "HU",
    name: "Hungary",
  },
  {
    dial_code: "+354",
    flag: "https://cdn.countryflags.com/thumbs/iceland/flag-square-250.png",
    code: "IS",
    name: "Iceland",
  },
  {
    dial_code: "+91",
    flag: "https://cdn.countryflags.com/thumbs/india/flag-square-250.png",
    code: "IN",
    name: "India",
  },
  {
    dial_code: "+353",
    flag: "https://cdn.countryflags.com/thumbs/ireland/flag-square-250.png",
    code: "IE",
    name: "Ireland",
  },
  {
    dial_code: "+972",
    flag: "https://cdn.countryflags.com/thumbs/israel/flag-square-250.png",
    code: "IL",
    name: "Israel",
  },
  {
    dial_code: "+1 876",
    flag: "https://cdn.countryflags.com/thumbs/jamaica/flag-square-250.png",
    code: "JM",
    name: "Jamaica",
  },
  {
    dial_code: "+81",
    flag: "https://cdn.countryflags.com/thumbs/japan/flag-square-250.png",
    code: "JP",
    name: "Japan",
  },
  {
    dial_code: "+962",
    flag: "https://cdn.countryflags.com/thumbs/jordan/flag-square-250.png",
    code: "JO",
    name: "Jordan",
  },
  {
    dial_code: "+7",
    flag: "https://cdn.countryflags.com/thumbs/kazakhstan/flag-square-250.png",
    code: "KZ",
    name: "Kazakhstan",
  },
  {
    dial_code: "+254",
    flag: "https://cdn.countryflags.com/thumbs/kenya/flag-square-250.png",
    code: "KE",
    name: "Kenya",
  },
  {
    dial_code: "+686",
    flag: "https://cdn.countryflags.com/thumbs/kiribati/flag-square-250.png",
    code: "KI",
    name: "Kiribati",
  },
  {
    dial_code: "+965",
    flag: "https://cdn.countryflags.com/thumbs/kuwait/flag-square-250.png",
    code: "KW",
    name: "Kuwait",
  },
  {
    dial_code: "+996",
    flag: "https://cdn.countryflags.com/thumbs/kyrgyzstan/flag-square-250.png",
    code: "KG",
    name: "Kyrgyzstan",
  },
  {
    dial_code: "+856",
    flag: "https://cdn.countryflags.com/thumbs/laos/flag-square-250.png",
    code: "LA",
    name: "Laos",
  },
  {
    dial_code: "+371",
    flag: "https://cdn.countryflags.com/thumbs/latvia/flag-square-250.png",
    code: "LV",
    name: "Latvia",
  },
  {
    dial_code: "+961",
    flag: "https://cdn.countryflags.com/thumbs/lebanon/flag-square-250.png",
    code: "LB",
    name: "Lebanon",
  },
  {
    dial_code: "+266",
    flag: "https://cdn.countryflags.com/thumbs/lesotho/flag-square-250.png",
    code: "LS",
    name: "Lesotho",
  },
  {
    dial_code: "+231",
    flag: "https://cdn.countryflags.com/thumbs/liberia/flag-square-250.png",
    code: "LR",
    name: "Liberia",
  },
  {
    dial_code: "+423",
    flag: "https://cdn.countryflags.com/thumbs/liechtenstein/flag-square-250.png",
    code: "LI",
    name: "Liechtenstein",
  },
  {
    dial_code: "+370",
    flag: "https://cdn.countryflags.com/thumbs/lithuania/flag-square-250.png",
    code: "LT",
    name: "Lithuania",
  },
  {
    dial_code: "+352",
    flag: "https://cdn.countryflags.com/thumbs/luxembourg/flag-square-250.png",
    code: "LU",
    name: "Luxembourg",
  },
  {
    dial_code: "+389",
    flag: "https://cdn.countryflags.com/thumbs/macedonia/flag-square-250.png",
    code: "MK",
    name: "Macedonia",
  },
  {
    dial_code: "+265",
    flag: "https://cdn.countryflags.com/thumbs/malawi/flag-square-250.png",
    code: "MW",
    name: "Malawi",
  },
  {
    dial_code: "+960",
    flag: "https://cdn.countryflags.com/thumbs/maldives/flag-square-250.png",
    code: "MV",
    name: "Maldives",
  },
  {
    dial_code: "+223",
    flag: "https://cdn.countryflags.com/thumbs/somalia/flag-square-250.png",
    code: "ML",
    name: "Mali",
  },
  {
    dial_code: "+356",
    flag: "https://cdn.countryflags.com/thumbs/malta/flag-square-250.png",
    code: "MT",
    name: "Malta",
  },
  {
    dial_code: "+692",
    flag: "https://cdn.countryflags.com/thumbs/marshall-islands/flag-square-250.png",
    code: "MH",
    name: "Marshall Islands",
  },
  {
    dial_code: "+222",
    flag: "https://cdn.countryflags.com/thumbs/mauritania/flag-square-250.png",
    code: "MR",
    name: "Mauritania",
  },
  {
    dial_code: "+230",
    flag: "https://cdn.countryflags.com/thumbs/mauritius/flag-square-250.png",
    code: "MU",
    name: "Mauritius",
  },
  {
    dial_code: "+691",
    flag: "https://cdn.countryflags.com/thumbs/micronesia/flag-square-250.png",
    code: "FM",
    name: "Micronesia",
  },
  {
    dial_code: "+373",
    flag: "https://cdn.countryflags.com/thumbs/moldova/flag-square-250.png",
    code: "MD",
    name: "Moldova",
  },
  {
    dial_code: "+377",
    flag: "https://cdn.countryflags.com/thumbs/monaco/flag-square-250.png",
    code: "MC",
    name: "Monaco",
  },
  {
    dial_code: "+976",
    flag: "https://cdn.countryflags.com/thumbs/mongolia/flag-square-250.png",
    code: "MN",
    name: "Mongolia",
  },
  {
    dial_code: "+258",
    flag: "https://cdn.countryflags.com/thumbs/mozambique/flag-square-250.png",
    code: "MZ",
    name: "Mozambique",
  },
  {
    dial_code: "+264",
    flag: "https://cdn.countryflags.com/thumbs/namibia/flag-square-250.png",
    code: "NA",
    name: "Namibia",
  },
  {
    dial_code: "+674",
    flag: "https://cdn.countryflags.com/thumbs/nauru/flag-square-250.png",
    code: "NR",
    name: "Nauru",
  },
  {
    dial_code: "+31",
    flag: "https://cdn.countryflags.com/thumbs/netherlands/flag-square-250.png",
    code: "NL",
    name: "Netherlands",
  },
  {
    dial_code: "+64",
    flag: "https://cdn.countryflags.com/thumbs/new-zealand/flag-square-250.png",
    code: "NZ",
    name: "New Zealand",
  },
  {
    dial_code: "+505",
    flag: "https://cdn.countryflags.com/thumbs/nicaragua/flag-square-250.png",
    code: "NI",
    name: "Nicaragua",
  },
  {
    dial_code: "+227",
    flag: "https://cdn.countryflags.com/thumbs/nigeria/flag-square-250.png",
    code: "NE",
    name: "Niger",
  },
  {
    dial_code: "+234",
    flag: "https://cdn.countryflags.com/thumbs/nigeria/flag-square-250.png",
    code: "NG",
    name: "Nigeria",
  },
  {
    dial_code: "+683",
    flag: "https://cdn.countryflags.com/thumbs/niue/flag-square-250.png",
    code: "NU",
    name: "Niue",
  },
  {
    dial_code: "+47",
    flag: "https://cdn.countryflags.com/thumbs/norway/flag-square-250.png",
    code: "NO",
    name: "Norway",
  },
  {
    dial_code: "+968",
    flag: "https://cdn.countryflags.com/thumbs/romania/flag-square-250.png",
    code: "OM",
    name: "Oman",
  },
  {
    dial_code: "+92",
    flag: "https://cdn.countryflags.com/thumbs/pakistan/flag-square-250.png",
    code: "PK",
    name: "Pakistan",
  },
  {
    dial_code: "+680",
    flag: "https://cdn.countryflags.com/thumbs/palau/flag-square-250.png",
    code: "PW",
    name: "Palau",
  },
  {
    dial_code: "+507",
    flag: "https://cdn.countryflags.com/thumbs/panama/flag-square-250.png",
    code: "PA",
    name: "Panama",
  },
  {
    dial_code: "+675",
    flag: "https://cdn.countryflags.com/thumbs/papua-new-guinea/flag-square-250.png",
    code: "PG",
    name: "Papua New Guinea",
  },
  {
    dial_code: "+595",
    flag: "https://cdn.countryflags.com/thumbs/paraguay/flag-square-250.png",
    code: "PY",
    name: "Paraguay",
  },
  {
    dial_code: "+351",
    flag: "https://cdn.countryflags.com/thumbs/portugal/flag-square-250.png",
    code: "PT",
    name: "Portugal",
  },
  {
    dial_code: "+974",
    flag: "https://cdn.countryflags.com/thumbs/qatar/flag-square-250.png",
    code: "QA",
    name: "Qatar",
  },
  {
    dial_code: "+40",
    flag: "https://cdn.countryflags.com/thumbs/romania/flag-square-250.png",
    code: "RO",
    name: "Romania",
  },
  {
    dial_code: "+250",
    flag: "https://cdn.countryflags.com/thumbs/rwanda/flag-square-250.png",
    code: "RW",
    name: "RWANDA",
  },
  {
    dial_code: "+1 869",
    flag: "https://cdn.countryflags.com/thumbs/saint-kitts-and-nevis/flag-square-250.png",
    code: "KN",
    name: "Saint Kitts and Nevis",
  },
  {
    dial_code: "+1 758",
    flag: "https://cdn.countryflags.com/thumbs/saint-lucia/flag-square-250.png",
    code: "LC",
    name: "Saint Lucia",
  },
  {
    dial_code: "+1 784",
    flag: "https://cdn.countryflags.com/thumbs/saint-vincent-and-the-grenadines/flag-square-250.png",
    code: "VC",
    name: "Saint Vincent and the Grenadines",
  },
  {
    dial_code: "+685",
    flag: "https://cdn.countryflags.com/thumbs/samoa/flag-square-250.png",
    code: "WS",
    name: "Samoa",
  },
  {
    dial_code: "+378",
    flag: "https://cdn.countryflags.com/thumbs/san-marino/flag-square-250.png",
    code: "SM",
    name: "San Marino",
  },
  {
    dial_code: "+239",
    flag: "https://cdn.countryflags.com/thumbs/sao-tome-and-principe/flag-square-250.png",
    code: "ST",
    name: "Sao Tome and Principe",
  },
  {
    dial_code: "+221",
    flag: "https://cdn.countryflags.com/thumbs/senegal/flag-square-250.png",
    code: "SN",
    name: "Senegal",
  },
  {
    dial_code: "+381",
    flag: "https://cdn.countryflags.com/thumbs/serbia/flag-square-250.png",
    code: "RS",
    name: "Serbia",
  },
  {
    dial_code: "+382",
    flag: "https://cdn.countryflags.com/thumbs/montenegro/flag-square-250.png",
    code: "ME",
    name: "Montenegro",
  },
  {
    dial_code: "+248",
    flag: "https://cdn.countryflags.com/thumbs/seychelles/flag-square-250.png",
    code: "SC",
    name: "Seychelles",
  },
  {
    dial_code: "+232",
    flag: "https://cdn.countryflags.com/thumbs/sierra-leone/flag-square-250.png",
    code: "SL",
    name: "Sierra Leone",
  },
  {
    dial_code: "+65",
    flag: "https://cdn.countryflags.com/thumbs/singapore/flag-square-250.png",
    code: "SG",
    name: "Singapore",
  },
  {
    dial_code: "+421",
    flag: "https://cdn.countryflags.com/thumbs/slovakia/flag-square-250.png",
    code: "SK",
    name: "Slovakia",
  },
  {
    dial_code: "+386",
    flag: "https://cdn.countryflags.com/thumbs/slovenia/flag-square-250.png",
    code: "SI",
    name: "Slovenia",
  },
  {
    dial_code: "+677",
    flag: "https://cdn.countryflags.com/thumbs/solomon-islands/flag-square-250.png",
    code: "SB",
    name: "Solomon Islands",
  },
  {
    dial_code: "+252",
    flag: "https://cdn.countryflags.com/thumbs/somalia/flag-square-250.png",
    code: "SO",
    name: "Somalia",
  },
  {
    dial_code: "+27",
    flag: "https://cdn.countryflags.com/thumbs/south-africa/flag-square-250.png",
    code: "ZA",
    name: "South Africa",
  },
  {
    dial_code: "+94",
    flag: "https://cdn.countryflags.com/thumbs/sri-lanka/flag-square-250.png",
    code: "LK",
    name: "Sri Lanka",
  },
  {
    dial_code: "+249",
    flag: "https://cdn.countryflags.com/thumbs/south-sudan/flag-square-250.png",
    code: "SD",
    name: "Sudan",
  },
  {
    dial_code: "+597",
    flag: "https://cdn.countryflags.com/thumbs/suriname/flag-square-250.png",
    code: "SR",
    name: "Suriname",
  },
  {
    dial_code: "+268",
    flag: "https://cdn.countryflags.com/thumbs/swaziland/flag-square-250.png",
    code: "SZ",
    name: "Swaziland",
  },
  {
    dial_code: "+46",
    flag: "https://cdn.countryflags.com/thumbs/sweden/flag-square-250.png",
    code: "SE",
    name: "Sweden",
  },
  {
    dial_code: "+41",
    flag: "https://cdn.countryflags.com/thumbs/switzerland/flag-square-250.png",
    code: "CH",
    name: "Switzerland",
  },
  {
    dial_code: "+992",
    flag: "https://cdn.countryflags.com/thumbs/tajikistan/flag-square-250.png",
    code: "TJ",
    name: "Tajikistan",
  },
  {
    dial_code: "+255",
    flag: "https://cdn.countryflags.com/thumbs/tanzania/flag-square-250.png",
    code: "TZ",
    name: "Tanzania",
  },
  {
    dial_code: "+228",
    flag: "https://cdn.countryflags.com/thumbs/togo/flag-square-250.png",
    code: "TG",
    name: "Togo",
  },
  {
    dial_code: "+676",
    flag: "https://cdn.countryflags.com/thumbs/tonga/flag-square-250.png",
    code: "TO",
    name: "Tonga",
  },
  {
    dial_code: "+1 868",
    flag: "https://cdn.countryflags.com/thumbs/trinidad-and-tobago/flag-square-250.png",
    code: "TT",
    name: "Trinidad and Tobago",
  },
  {
    dial_code: "+216",
    flag: "https://cdn.countryflags.com/thumbs/tunisia/flag-square-250.png",
    code: "TN",
    name: "Tunisia",
  },
  {
    dial_code: "+993",
    flag: "https://cdn.countryflags.com/thumbs/turkmenistan/flag-square-250.png",
    code: "TM",
    name: "Turkmenistan",
  },
  {
    dial_code: "+688",
    flag: "https://cdn.countryflags.com/thumbs/tuvalu/flag-square-250.png",
    code: "TV",
    name: "Tuvalu",
  },
  {
    dial_code: "+256",
    flag: "https://cdn.countryflags.com/thumbs/uganda/flag-square-250.png",
    code: "UG",
    name: "Uganda",
  },
  {
    dial_code: "+971",
    flag: "https://cdn.countryflags.com/thumbs/united-arab-emirates/flag-square-250.png",
    code: "AE",
    name: "United Arab Emirates",
  },
  {
    dial_code: "+1 473",
    flag: "https://cdn.countryflags.com/thumbs/united-kingdom/flag-square-250.png",
    code: "GB",
    name: "United Kingdom",
  },
  {
    dial_code: "+1",
    flag: "https://cdn.countryflags.com/thumbs/united-states-of-america/flag-square-250.png",
    code: "US",
    name: "United States",
  },
  {
    dial_code: "+598",
    flag: "https://cdn.countryflags.com/thumbs/uruguay/flag-square-250.png",
    code: "UY",
    name: "Uruguay",
  },
  {
    dial_code: "+260",
    flag: "https://cdn.countryflags.com/thumbs/zambia/flag-square-250.png",
    code: "ZM",
    name: "Zambia",
  },
  {
    dial_code: "+263",
    flag: "https://cdn.countryflags.com/thumbs/zimbabwe/flag-square-250.png",
    code: "ZW",
    name: "Zimbabwe",
  },
];

export const settings_menus = [
  {
    name: "Account",
    icon: "fa fa-user",
    route: "/app/settings/account",
  },
  {
    name: "Default Organization",
    icon: "fa fa-home",
    route: "/app/settings/defaultorg",
  },
];

export const HUZAMART_IN_COUNTRIES = [
  {
    dial_code: "+250",
    flag: "https://cdn.countryflags.com/thumbs/rwanda/flag-square-250.png",
    code: "RW",
    name: "RWANDA",
  },
  {
    dial_code: "+255",
    flag: "https://cdn.countryflags.com/thumbs/tanzania/flag-square-250.png",
    code: "TZ",
    name: "TANZANIA",
  },
];


export const HUZAMART_IN_DEALS = [
  {
    type: "retail",
  },
  {
    type: "wholesale"
  },
];

