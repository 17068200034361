import React from "react";
import { Link } from "react-router-dom";

const ActionButton = ({
  show_edit,
  show_delete,
  onDelete,
  edit_route,
  onEdit,
}) => (
  <>
    {show_edit && (
      <Link to="#" onClick={onEdit}>
        <b>Edit</b>
      </Link>
    )}
    <span style={{ marginLeft: 10, marginRight: 10 }} />
    {show_delete && (
      <Link to="#" onClick={onDelete} style={{ color: "red" }}>
        <b>Delete</b>
      </Link>
    )}
  </>
);

export default ActionButton;
