import React from "react";
import "./styles.css";
import { Loader } from "../Loader";

class MessageContent extends React.Component {
  constructor() {
    super();

    this.messagesEnd = React.createRef();
  }

  scrollToBottom = () => {
    const scroll =
      this.messagesEnd.current.scrollHeight -
      this.messagesEnd.current.clientHeight;
    this.messagesEnd.current.scrollTo(0, scroll);
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  render() {
    const {
      conversations,
      is_loading,
      user,
      message,
      onChangeMessage,
      onSubmit,
      handleKeyDown,
    } = this.props;
    return (
      <div className="message_content">
        <div className="message_list" ref={this.messagesEnd}>
          {is_loading ? (
            <Loader />
          ) : (
            <div className="messages">
              <ul>
                {conversations.map((item, i) => {
                  if (item.sender.includes(user.user_id)) {
                    return (
                      <li key={i} className="right">
                        <span>{item.message}</span>
                      </li>
                    );
                  } else {
                    return (
                      <li key={i} className="left">
                        <span>{item.message}</span>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          )}
        </div>
        <div className="type_message">
          <input
            className="form-control"
            placeholder="Type message..."
            value={message}
            onChange={onChangeMessage}
            onKeyDown={handleKeyDown}
          />
          <button className="btn" onClick={onSubmit}>
            <i className="fa fa-paper-plane" />
            <span>Send</span>
          </button>
        </div>
      </div>
    );
  }
}

export default MessageContent;
