import React from "react";
import { USER_IMAGE_PLACEHOLDER } from "../../utils/CONSTANTS";
import "./styles.css";
import TimeAgo from "timeago-react";

const UserMessage = ({ message, handlePress }) => (
  <div className="user_message" onClick={handlePress}>
    <img
      src={
        message.buyer &&
        message.buyer[0].file_url &&
        message.buyer[0].file_url.length !== 0
          ? message.buyer[0].file_url[0]
          : USER_IMAGE_PLACEHOLDER
      }
      className="profile"
    />
    <div className="message_info">
      <div className="header">
        <h1>
          {message.buyer[0].phone_number !== ""
            ? message.buyer[0].phone_number
            : message.buyer[0].email}
        </h1>
        <span>
          <TimeAgo datetime={message.date} />
        </span>
      </div>
      <div className="message">{message.message}</div>
    </div>
  </div>
);

export default UserMessage;
