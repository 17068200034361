import React from "react";
import { Header } from "../components/Header";
import { List } from "../components/List";
import { TableAccount, TableOrg } from "../components/Table";
import { settings_menus } from "../utils/CONSTANTS";
import { getStorage } from "../utils/sessionStorage";
import { connect } from "react-redux";

class SettingScreen extends React.Component {
  state = {
    data: [],
    columns: ["Name", "Email", "Phone", "Tag"],
    organization: [],
    org_columns: ["Name", "Category", "Product", "Order"],
  };

  componentDidMount = async () => {
    await this.getProfile();
  };

  getProfile = () => {
    let user = getStorage();
    if (user && user.token) {
      this.setState({
        user,
        data: [user],
        organization: [this.props.organization],
      });
    }
  };

  render() {
    return (
      <>
        <Header title="Settings" />
        <div className="row">
          <div className="col-md-3">
            <div className="list-group">
              {settings_menus.map((item, i) => {
                return (
                  <List
                    key={i}
                    active={
                      this.props.match.params.tag === "defaultorg" &&
                      item.name.toLowerCase() === "default organization"
                        ? true
                        : this.props.match.params.tag ===
                          item.name.toLowerCase()
                    }
                    icon={item.icon}
                    title={item.name}
                    route={item.route}
                  />
                );
              })}
            </div>
          </div>
          <div className="col-md-9">
            {this.props.match.params.tag === "account" && (
              <TableAccount
                data={this.state.data}
                columns={this.state.columns}
              />
            )}
            {this.props.match.params.tag === "defaultorg" && (
              <TableOrg
                data={this.state.organization}
                columns={this.state.org_columns}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapPropsToState = (state) => {
  const { organization } = state.Organization;
  return {
    organization,
  };
};

export default connect(mapPropsToState)(SettingScreen);
