import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { TableActionButton } from ".";
import moment from "moment";

const TableOrg = ({ className, data, handlePress, openModal, onDelete }) => (
  <table className={`table table-hover ${className}`}>
    <thead>
      <tr>
        <th>Amount</th>
        <th>Phone number</th>
        <th>Organization</th>
        <th>Service</th>
        <th>Status</th>
        <th>Date</th>
      </tr>
    </thead>
    <tbody>
      {data.length === 0 ? (
        <tr>
          <td align="center" colSpan={10}>
            No transaction found
          </td>
        </tr>
      ) : (
        data.map((item, i) => {
          return (
            <tr key={i}>
              <td>
                {item.service
                  ? item.service.currency + " " + item.amount
                  : item.amount}
              </td>
              <td>{item.paynumber ? item.paynumber : "-"}</td>
              <td>{item.organization ? item.organization.name : "-"}</td>
              <td>{item.service ? item.service.service_category : "-"}</td>
              <td>
                {item.has_paid && item.has_paid.toLowerCase() === "paid" ? (
                  <span className="badge badge-primary">{"Paid"}</span>
                ) : item.has_paid && item.has_paid.toLowerCase() === "partial" ? (
                  <span className="badge badge-warning">{"Partial"}</span>
                ) : (
                  <span className="badge badge-danger">{"Not-paid"}</span>
                )}
              </td>
              <td>{moment(item.date).format("lll")}</td>
            </tr>
          );
        })
      )}
    </tbody>
  </table>
);

export default TableOrg;
