import React from "react"
import "./styles.css"
import { Product } from "../Product"
import { Link } from "react-router-dom"

// let sub_total = 0, total = 0

const OrderDetail = ({data, sub_total, total}) => <div className="row">
    <div className="col-md-12 mb-1">
        <div className="card">
        <div className="card-body">
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th>Order ID#</th>
                    <th>Cost</th>
                    <th>Address</th>
                    <th>Consumer</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><b>{data[0]._id}</b></td>
                    <td><b>{data[0].currency+" "+data[0].cost}</b></td>
                    <td><b>{data[0].address}</b></td>
                    <td><b><a href="#">{data[0].consumer[0].email ? data[0].consumer[0].email : data[0].consumer[0].phone_number}</a></b></td>
                </tr>
            </tbody>
        </table>
    </div>
        </div>
    </div>
    <div className="col-md-12">
        <div className="card">
            <div className="card-body">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th style={{textAlign:"right"}}>QTY</th>
                            <th style={{textAlign:"right"}}>Unit Price</th>
                            <th style={{textAlign:"right"}}>Sum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data[0].product.map((item, i) => {
                                // console.log("item: ", item)
                                // console.log("sub_total: ", sub_total)

                                sub_total += (item.price * data[0].quantity[i])
                                if(i==(data[0].product.length-1))
                                    total = sub_total + data[0].delivery_cost
                                return <tr key={i}>
                                    <td>
                                        <Link to={`/app/products/${item._id}`}>
                                            <Product title={item.title} file_url={item.file_url}/>
                                        </Link>
                                    </td>
                                    <td align="right">{data[0].quantity[i]}</td>
                                    <td align="right">
                                        <b>{item.currency+" "+item.price}</b>
                                    </td>
                                    <td align="right"><b>{item.currency+" "+(item.price * data[0].quantity[i])}</b></td>
                                </tr>
                            })
                        }
                        <tr>
                            <td colSpan={3}><b>Sub-Total:</b> </td>
                            {
                                console.log(sub_total)
                            }
                            <td colSpan={4} align="right"><b>{data[0].currency+" "+sub_total}</b></td>
                        </tr>
                        <tr>
                            <td colSpan={3}><b>Delivery cost:</b> </td>
                            <td colSpan={4} align="right"><b>{data[0].currency+" "+data[0].delivery_cost}</b></td>
                        </tr>
                        <tr>
                            <td colSpan={3}><b>Total: </b></td>
                            {
                                console.log(total)
                            }
                            <td colSpan={4} align="right"><b>{data[0].currency+" "+total}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

export default OrderDetail